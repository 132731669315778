import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import { useCoopConfig, getIdCatCreditoPrecosecha } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import DebtCalculator from '../../nuup/utils/debtCalculator.js';
import xlsx from 'json-as-xlsx';
import moment from 'moment/moment.js';
import getBackgroundColor from '../../utils/getBackgroundColor.js';
import getColor from '../../utils/getColor.js';
import getEstatus from '../../nuup/utils/getStatusDebtColor.js';
import LinkDownload from '../../components/link/link-download.jsx';
import insertIf from '../../utils/insertIf.js';

const AnticiposXProductorList = ({ update, ciclo, etiquetaAnticipos }) => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(true);

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        id: 'acopio_uuid',
        columns: [
          {
            Header: 'CLAVE',
            accessor: 'clave_tx',
            sortable: true,
            minWidth: 60,
          },
          {
            Header: 'CÓDIGO',
            accessor: 'codigo_tx',
            sortable: true,
            minWidth: 80,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'MUNICIPIO',
            accessor: 'municipio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color} />;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: `ESTATUS DE ${transformWord(etiquetaAnticipos, ['plural', 'uppercase'])}`,
        columns: [
          {
            Header: `# ${transformWord(etiquetaAnticipos, ['plural', 'uppercase'])}`,
            accessor: 'num_creditos',
            sortable: true,
            minWidth: 80,
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            width: 80,
            Cell: ({ row }) => {
              if (Number(row.original.num_creditos) > 0) {
                return (
                  <LinkDownload
                    isZip
                    url={`recibos/cuentas/anticipos/${ciclo.id}/${row.original.productor_uuid}`}
                    label="Descargar"
                    icon={env.images.icon.pdf}
                  />
                );
              } else return 'no hay recibos disponibles por el momento';
            },
          },
          {
            Header: 'IMPORTE TOTAL',
            accessor: 'total',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: `CAPITAL DESEMBOLSADO`,
            accessor: 'total_anticipos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: 'INTERÉS ACUMULADO',
            accessor: 'total_interes',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={value === 0 ? 0 : 2} />,
          },
          {
            Header: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>,
          },
          {
            Header: 'COMISIÓN',
            accessor: 'total_comisiones',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>,
          },
          {
            Header: 'SALDO',
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} digits={2} currencyLabel={coopConfig.currency()?.symbol} />,
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus',
            sortable: true,
            minWidth: 50,
            Cell: ({ row, value }) => <LabelColor label={value} color={row.original.estatus_color} />,
          },
        ],
      },
    ],
    [etiquetaAnticipos]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'clave_tx', label: 'CLAVE' },
      ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { value: 2, target: 'codigo_tx', label: 'CÓDIGO' }),
      { value: 3, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 4, target: 'localidad', label: 'LOCALIDAD' },
      { value: 5, target: 'region', label: 'REGIÓN' },
      { value: 6, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 7, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      { value: 8, target: 'fecha_ejecucion', label: 'FECHA DESEMBOLSO' },
      ...insertIf(getIdCatCreditoPrecosecha() > 2, { value: 9, target: 'total', label: 'IMPORTE TOTAL' }),
      { value: 10, target: 'total_anticipos', label: 'CAPITAL DESEMBOLSADO' },
      ...insertIf(getIdCatCreditoPrecosecha() > 2, { value: 11, target: 'total_interes', label: 'INTERÉS ACUMULADO' }),
      {
        value: 13,
        target: 'total_anticipos_recuperados',
        label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
      },
      { value: 14, target: 'total_saldo', label: 'SALDO' },
      { value: 15, target: 'estatus', label: 'ESTATUS' },
      { value: 16, target: 'registro', label: 'REGISTRO' },
    ],
    [update, etiquetaAnticipos]
  );

  useEffect(() => {
    if (ciclo.id) {
      updateData(ciclo.id);
    }
  }, [ciclo, update]);

  const updateData = id_ciclo => {
    setBlocking(true);
    const interesCoop = coopConfig.catCreditoPrecosecha()?.interes;
    Api.Acopio.search('anticipos_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        const updatedData = response.data.map(productor => {
          let total = 0;
          let total_anticipos = 0;
          let total_interes = 0;
          let total_anticipos_recuperados = 0;
          let total_comisiones = 0;
          let total_saldo = 0;
          let saldoEstatus = -1;

          // RETURN DATA WITH DEBT CALCULATION
          if (getIdCatCreditoPrecosecha() > 2) {
            productor.anticipos.forEach(anticipo => {
              const debtCalc = new DebtCalculator({
                credito: anticipo,
                interestRate: anticipo.tasa_interes && +anticipo.tasa_interes > 0 ? +anticipo.tasa_interes : +interesCoop,
                pagos: anticipo.anticipo_pagos,
                stringDate: null,
              });
              const calc = debtCalc.getDebt();
              if (calc.debtToday.pendingCapital / 100 >= 0.01) saldoEstatus = 1;
              total += +calc.debtToday.totalInterest / 100 + +anticipo.cantidad_anticipo / 100;
              total_anticipos += +anticipo.cantidad_anticipo / 100;
              total_interes += calc.debtToday.totalInterest / 100;
              total_anticipos_recuperados += anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.cantidad_pago / 100, 0);
              total_comisiones += anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.comision_efectivo / 100, 0);
              total_saldo += calc.debtToday.pendingCapital / 100;
            });
          } else {
            // RETURN DATA WITH NO INTEREST RATE

            productor.anticipos.forEach(anticipo => {
              const recuperado = anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.cantidad_pago / 100, 0);
              total += +anticipo.cantidad_anticipo / 100;
              total_anticipos += +anticipo.cantidad_anticipo / 100;
              total_interes += 0;
              total_anticipos_recuperados += recuperado;
              total_comisiones += anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.comision_efectivo / 100, 0);
              total_saldo += +anticipo.cantidad_anticipo / 100 - +total_anticipos_recuperados;
              if (+anticipo.cantidad_anticipo / 100 - +recuperado >= 0.01) saldoEstatus = 1;
            });
          }

          const estatus = getEstatus(saldoEstatus);
          return {
            ...productor,
            total,
            total_anticipos,
            total_interes,
            total_anticipos_recuperados,
            total_comisiones,
            total_saldo,
            estatus: estatus.label,
            estatus_color: estatus.color,
          };
        });
        setData(updatedData);
        setOriginalData(updatedData);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const exportarAnticipos = () => {
    let xlsData1 = [
      {
        sheet: 'Anticipos',
        columns: [
          { label: 'CLAVE', value: 'clave_tx' },
          ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { label: 'CÓDIGO', value: 'codigo_tx' }),
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'IMPORTE TOTAL',
            value: 'total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          { label: 'CAPITAL DESEMBOLSADO', value: row => +row.total_anticipos, format: `${coopConfig.currency()?.symbol}#,##0.00` },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'INTERÉS ACUMULADO',
            value: 'total_interes',
            format: `${coopConfig.currency()?.symbol}0.00`,
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'INTERÉS ACUMULADO',
            value: 'total_interes',
            format: `${coopConfig.currency()?.symbol}0.00`,
          }),
          {
            label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADOS',
            value: row => +row.total_anticipos_recuperados,
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'COMISIÓN',
            value: 'total_comisiones',
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: ' SALDO ',
            value: row => (row.total_saldo < 0.01 && row.total_saldo > -0.01 ? 0 : row.total_saldo),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          { label: 'ESTATUS', value: 'estatus' },
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `Anticipos_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  return (
    <>
      <div className="row">
        <div className="col-10">
          <SearchColumn
            data={originalData}
            columns={searchColumns}
            setDataFilter={evt => {
              setDataFilter(evt);
            }}></SearchColumn>
        </div>
        <div className="col-2">
          {ciclo?.uuid && auth.hasPermission('ACOPIOS_ANTICIPOS_X_PRODUCTOR_EXPORTAR') && (
            <div className="d-flex justify-content-end">
              <button onClick={() => exportarAnticipos()} className={`btn`} type="button">
                <img src={env.images.icon.xls} className="pr-1" />
                <p style={{ color: '#789D16' }}>Descargar Anticipos</p>
              </button>
            </div>
          )}
        </div>
      </div>

      <NoPagination
        blocking={blocking}
        selected="id"
        columns={columns}
        data={data}
        fnBackgroundColor={getBackgroundColor}
        fnColor={getColor}></NoPagination>
    </>
  );
};

export default AnticiposXProductorList;
