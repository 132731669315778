import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function FormSelectMaterial({
  error = false,
  label = '',
  id,
  options = [],
  onChange,
  register,
  height = 44,
  showAllLabel = 'TODOS',
  showAll = false,
  multiple = false,
  disabled = false,
  ...inputProps
}) {
  const theme = useTheme();
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id={id + '-label'} className="pr-1">{label}</InputLabel>
        <Select
          labelId={id + '-label'}
          id={id}
          multiple={multiple}
          value={inputProps.value}
          defaultValue={inputProps.value}
          onChange={e => onChange(e.target.value)}
          error={Boolean(error)}
          style={{ height: height, minWidth: 90, fontSize: '0.875rem' }}
          className='mt-1'
          label={label}
          disabled={disabled}>
          {showAll && (
            <MenuItem key={0} value={0}>
              {showAllLabel}
            </MenuItem>
          )}

          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
