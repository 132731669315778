import React, { useState, useEffect, createRef } from 'react';
import Api from '../../api/global.service';
import { Link, useParams } from 'react-router-dom';
import { Panel } from '../../components/panel/panel.jsx';
import CardBasic from '../../components/card/card-basic.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import BlockUi from 'react-block-ui';
import GoogleMapReact from 'google-map-react';
import env from './../../environment.js';
import BackButton from '../../components/link/back-button.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import TerrainDirection from '../../components/map/terrain-direction.jsx';
import NorthIcon from '../../components/map/north-icon.jsx';
import useCroquisHook from '../../hooks/useCroquisHook.jsx';
import MapText from '../../components/card/map-text.jsx';
import generalCatchErrorsModal from '../../components/modal/modal-error';
import useGetColindancias from '../../hooks/useGetColindancias.jsx';
import MarkerInfoWindow from '../../components/map/marker-infowindow.jsx';
import parsePoint from '../../utils/parsePoint.js';
import ParcelasAlertas from './parcelas-alertas.js';
import Swal from 'sweetalert2';
import CardTitleParcela from '../../components/card/card-title-parcela.jsx';
import useDownloadKml from '../../hooks/useDownloadKml.js';
import deleteCroquis from '../../nuup/utils/deleteCroquis.js';
import abrirDibujoCroquis from '../../nuup/utils/openImageCroquis.js';

const ParcelaProfile = () => {
  const { uuid } = useParams();
  const [parcela, setParcela] = useState({});
  const [parcelaData, setParcelaData] = useState({});
  const [centroid, setCentroid] = useState(null);
  const [blocking, setBlocking] = useState(false);
  const [dataInDegrees, setDataInDegrees] = useState(false);
  const [blockingCol, setBlockingCol] = useState(false);
  const [croquisCaptura, setCroquisCaptura] = useState(null);
  const [mapType, setMapType] = useState('satellite');
  const { areaGMaps, renderMap, center, angle, pointsOnMap, pointsOnMapDegrees, centroidProcessed, lengths, zoom } = useCroquisHook(
    parcelaData
  );
  const { colindancias } = useGetColindancias({ uuid, setBlocking: setBlockingCol });
  const [width, setWidth] = useState(window.innerWidth);
  const topHeight = 130;
  const fileUpload = createRef();
  const { downloadKml } = useDownloadKml();
  const uploadKML = () => fileUpload.current.click();

  const handleKmlUpload = async e => {
    if (e.target.files) {
      // setBlocking(true);
      const kml = e.target.files[0];
      //TODO: checar que el tipo de archivo es kml
      const formData = new FormData();
      formData.append('uuid', uuid);
      formData.append('kml', kml);
      Api.createMultiPart('kml_import', formData)
        .then(() => {
          // setBlocking(false);
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Se actualizó con éxito la el croquis de la parcela`,
            confirmButtonText: 'Continuar',
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = '/admin/parcelas/perfil/' + uuid;
            }
          });
        })
        .catch(e => {
          // setBlocking(false);
          Swal.fire({
            icon: 'error',
            title: 'Algo falló al actualizar el croquis de la parcela',
            text: e.response.data.message,
          });
        });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', function() {
      setWidth(window.innerWidth);
    });
    if (uuid != null) {
      setBlocking(true);
      Api.get('parcela_profile', uuid)
        .then(response => {
          setParcela(response.data);
          let cent;
          if (response?.data?.poligono) {
            cent = { lat: parseFloat(response?.data?.centroide_pol_lat), lng: parseFloat(response?.data?.centroide_pol_lng) };
            setCentroid(cent);
          } else if (!isNaN(response?.data?.centroide_lat) && !isNaN(response?.data?.centroide_lat)) {
            cent = { lat: parseFloat(response?.data?.centroide_lat), lng: parseFloat(response?.data?.centroide_lng) };
            setCentroid(cent);
          }
          setParcelaData({
            parcelaPolygon: response?.data?.poligono,
            centroid: cent,
          });
          setBlocking(false);
        })
        .catch(e => {
          generalCatchErrorsModal(e.response?.status, '/admin/parcelas/list');
          setBlocking(false);
        });

      Api.get('parcelas_croquis_captura', uuid)
        .then(response => {
          if (response.data && response.data.length > 0) {
            setCroquisCaptura(response.data);
          } else {
            setCroquisCaptura(env.noImage);
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('e', e);
        });
    }
  }, []);

  const dataPoints = dataInDegrees ? pointsOnMapDegrees : pointsOnMap;

  return (
    <>
      <div>
        <Breadcrumb>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/admin/parcelas">Parcelas</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/parcelas/list">Listado parcelas</Link>
            </li>
            <li className="breadcrumb-item active">Perfil parcela</li>
          </ol>
          <BackButton goBackDefault="/admin/parcelas/list" />
        </Breadcrumb>
        <div className="row justify-content-end ">
          <div className="col-4">
            <FormSelectMaterial
              id="tipoMapa"
              error={false}
              label="Tipo Mapa"
              value={mapType}
              onChange={v => setMapType(v)}
              height={36}
              options={[
                { value: 'satellite', label: 'Satelital' },
                { value: 'terrain', label: 'Terreno' },
              ]}
              showAll={false}
            />
          </div>
          <Link to={{ pathname: `/admin/parcelas/perfil/print/${uuid}` }} className="btn btn-outline-secondary mb-3">
            <i className="fa fa-print" aria-hidden="true"></i> Versión para compartir
          </Link>
        </div>

        <div className="row pb-4">
          <div className="col-xl-5 mb-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer height={topHeight}>
                <div className="row">
                  <CardTitleParcela
                    cuenta_uuid={parcela.cuenta_uuid}
                    cuenta_name={parcela.cuenta}
                    parcela_name={parcela.nombre}
                    localidad={parcela.localidad}
                    municipio={parcela.municipio}
                    certifications={parcela.certifications}
                  />
                </div>
              </CardContainer>
            </BlockUi>
          </div>
          <div className="col-xl-4 mb-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer height={topHeight}>
                <div className="row">
                  <div className="col-xl-2 col-md-2">
                    <img src={env.images.icon.area} style={{ height: '60px' }} />
                  </div>
                  <div className="col-5">
                    <h4 className="font-lato-normal-400">
                      {(areaGMaps || 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{' '}
                      has
                    </h4>
                    <p className="font-lato-normal-500 pb-3" style={{ color: env.colors.neutral_700 }}>
                      calculadas (automático)
                    </p>
                  </div>
                  <div className="col-5">
                    <h4 className="font-lato-normal-400">
                      {(parcela.superficie || 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{' '}
                      has
                    </h4>
                    <p className="font-lato-normal-500 pb-3" style={{ color: 'rgb(91, 91, 91)' }}>
                      reportadas del productor/a
                    </p>
                  </div>
                </div>
              </CardContainer>
            </BlockUi>
          </div>
          <div className="col-xl-3 mb-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer height={topHeight}>
                <div className="row">
                  <div className="col-xl-2 col-md-2">
                    <img src={env.images.icon.planta} style={{ height: '32px', marginTop: '6px' }} />
                  </div>
                  <div className="col-5">
                    <h4 className="font-lato-normal-400">
                      {(parcela.plantas || 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{' '}
                    </h4>
                    <p className="font-lato-normal-500 pb-3">Plantas</p>
                  </div>
                  <div className="col-5">
                    <h5 className="font-lato-normal-500 pb-3">{parcela.variedades}</h5>
                  </div>
                </div>
              </CardContainer>
            </BlockUi>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-xl-12">
            <BlockUi tag="div" message="" blocking={blocking || blockingCol} renderChildren={true}>
              <CardContainer>
                <Panel>
                  <div className="row mb-2">
                    <div className="col-3"></div>
                    <div className="col-3">
                      <span className="font-weight-bold">Colindancia Norte:</span> <span> {colindancias?.norte?.colindante}</span>
                    </div>
                    <div className="col-3">
                      <span className="font-weight-bold">Propietario/a:</span> <span> {colindancias?.norte?.propietario}</span>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-2 align-self-sm-center">
                      <p>
                        <span className="font-weight-bold">Colindancia Oeste:</span>
                      </p>
                      <p>
                        <span> {colindancias?.oeste?.colindante}</span>
                      </p>
                      <p>
                        <span className="font-weight-bold">Propietario/a:</span>
                      </p>
                      <p>
                        <span> {colindancias?.oeste?.propietario}</span>
                      </p>
                    </div>
                    <div className="col-xl-8 col-md-8">
                      <div className="map-rounded" style={{ height: '300px', width: '100%' }}>
                        <NorthIcon color={mapType === 'satellite' ? 'white' : 'black'} />

                        {parcela.poligono ? (
                          <>
                            <TerrainDirection angle={angle} color={mapType === 'satellite' ? 'white' : 'black'} />
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: env.googleApiKey,
                                libraries: ['geometry', 'elevation'],
                              }}
                              options={() => ({ mapTypeId: mapType })}
                              center={center}
                              zoom={zoom}
                              yesIWantToUseGoogleMapApiInternals
                              onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                              {lengths.map((it, i) => {
                                return (
                                  <MapText
                                    lat={it?.lat}
                                    lng={it?.lng}
                                    key={i}
                                    text={it?.length + 'm'}
                                    style={{ color: mapType === 'satellite' ? 'white' : 'black' }}
                                  />
                                );
                              })}
                              {pointsOnMap.map((it, i) => (
                                <MapText
                                  lat={it?.lat}
                                  lng={it?.lng}
                                  key={i}
                                  text={it?.title}
                                  style={{ color: mapType === 'satellite' ? 'white' : 'black' }}
                                />
                              ))}
                            </GoogleMapReact>
                          </>
                        ) : null}
                        {parcela.poligono === null &&
                          parcelaData?.centroid &&
                          !isNaN(parcelaData?.centroid.lat) &&
                          !isNaN(parcelaData?.centroid.lng) && (
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: env.googleApiKey, libraries: ['elevation'] }}
                              options={() => ({ mapTypeId: mapType })}
                              center={centroid}
                              zoom={zoom}
                              yesIWantToUseGoogleMapApiInternals
                              onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                              <MarkerInfoWindow
                                key={1}
                                selectedElement={1}
                                lat={parcelaData?.centroid?.lat}
                                lng={parcelaData?.centroid?.lng}
                                productor={'Polígono pendiente de registrar'}
                                color={'#91B833'}
                              />
                            </GoogleMapReact>
                          )}
                        {parcela.centroide === null && parcela.poligono === null && (
                          <CardBasic
                            height={290}
                            label="Ubicación no disponible"
                            value={''}
                            style={{ backgroundColor: env.colors.neutral_1 }}></CardBasic>
                        )}
                      </div>
                    </div>
                    <div className="col-2 align-self-sm-center">
                      <p>
                        <span className="font-weight-bold">Colindancia Este:</span>
                      </p>
                      <p>
                        <span> {colindancias?.este?.colindante}</span>
                      </p>
                      <p>
                        <span className="font-weight-bold">Propietario/a:</span>
                      </p>
                      <p>
                        <span> {colindancias?.este?.propietario}</span>
                      </p>
                    </div>
                  </div>
                  <div className="row  mb-4" style={{ height: 18 }}>
                    <div className="col-3"></div>
                    <div className="col-3">
                      <span className="font-weight-bold">Colindancia Sur:</span> <span> {colindancias?.sur?.colindante}</span>
                    </div>
                    <div className="col-3">
                      <span className="font-weight-bold">Propietario/a:</span> <span> {colindancias?.sur?.propietario}</span>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-2">
                      {parcela.poligono ? (
                        <>
                          <Link
                            style={{ width: '100%' }}
                            to={{ pathname: `/admin/parcelas/perfil/editcroquis/${uuid}` }}
                            className="btn btn-outline-secondary mb-3">
                            <i className="fa fa-pencil-alt mr-2" aria-hidden="true"></i> Editar
                          </Link>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <input type="file" ref={fileUpload} onChange={handleKmlUpload} style={{ display: 'none' }} />
                  <div className="row  mb-2 d-flex justify-content-center">
                    <div className={width > 1100 ? 'btn-group' : 'btn-group-vertical'} role="group" style={{ borderRadius: 1 }}>
                      <button
                        type="button"
                        onClick={() => {}}
                        className={'btn btn-light'}
                        style={{
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: width > 1100 ? 0 : 10,
                          borderBottomLeftRadius: width > 1100 ? 10 : 0,
                          backgroundColor: '#F6F7F5',
                          width: 140,
                        }}
                        disabled={true}>
                        <span
                          style={{
                            color: '#4A6700',
                          }}>
                          Ver Observaciones
                        </span>
                      </button>

                      <button
                        type="button"
                        onClick={() => abrirDibujoCroquis(croquisCaptura, window)}
                        className={'btn btn-light'}
                        style={{
                          backgroundColor: '#F6F7F5',
                          width: 140,
                        }}
                        disabled={!croquisCaptura}>
                        <span
                          style={{
                            color: '#4A6700',
                          }}>
                          Ver dibujo
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() => uploadKML(uuid)}
                        className={'btn btn-light'}
                        style={{
                          backgroundColor: '#F6F7F5',
                          width: 140,
                        }}
                        disabled={false}>
                        <span
                          style={{
                            color: '#4A6700',
                          }}>
                          Importar KML
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() => downloadKml(uuid, `${parcela.nombre}_${parcela.id}`, areaGMaps)}
                        className={'btn btn-light'}
                        style={{
                          backgroundColor: '#F6F7F5',
                          width: 140,
                        }}
                        disabled={!parcela.poligono}>
                        <span
                          style={{
                            color: '#4A6700',
                          }}>
                          Descargar Kml
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() => deleteCroquis(uuid, window)}
                        className={'btn btn-light'}
                        style={{
                          backgroundColor: '#F6F7F5',
                          width: 140,
                        }}
                        disabled={!parcela.poligono}>
                        <span
                          style={{
                            color: '#4A6700',
                          }}>
                          Eliminar Croquis
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() => {}}
                        className={'btn btn-light'}
                        style={{
                          borderTopRightRadius: width > 1100 ? 10 : 0,
                          borderBottomLeftRadius: width > 1100 ? 0 : 10,
                          borderBottomRightRadius: 10,
                          backgroundColor: '#F6F7F5',
                          width: 140,
                        }}
                        disabled={true}>
                        <span
                          style={{
                            color: '#4A6700',
                          }}>
                          Historial de cambios
                        </span>
                      </button>
                    </div>
                  </div>
                </Panel>
              </CardContainer>
            </BlockUi>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-xl-12">
            <BlockUi tag="div" message="" blocking={blocking || blockingCol} renderChildren={true}>
              <CardContainer height={width > 1100 ? 450 : 650}>
                <Panel>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="row mb-3" style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          <h4>Coordenadas</h4>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <button
                          type="button"
                          onClick={() => setDataInDegrees(false)}
                          className={'btn btn-light '}
                          style={{
                            borderRadius: 10,
                            backgroundColor: !dataInDegrees ? '#F1F2E2' : '#F6F7F5',
                            borderWidth: 0,
                            marginRight: 10,
                          }}
                          disabled={false}>
                          {!dataInDegrees ? (
                            <i
                              className="fa fa-check"
                              style={{
                                color: !dataInDegrees ? 'black' : '#777771',
                              }}
                            />
                          ) : null}{' '}
                          <span
                            style={{
                              color: !dataInDegrees ? 'black' : '#777771',
                            }}>
                            Decimales
                          </span>
                        </button>

                        <button
                          type="button"
                          onClick={() => setDataInDegrees(true)}
                          className={'btn btn-light'}
                          style={{
                            borderRadius: 10,
                            backgroundColor: dataInDegrees ? '#F1F2E2' : '#F6F7F5',
                            borderWidth: 0,
                          }}
                          disabled={false}>
                          {dataInDegrees ? (
                            <i
                              className="fa fa-check"
                              style={{
                                color: dataInDegrees ? 'black' : '#777771',
                              }}
                            />
                          ) : null}{' '}
                          <span
                            style={{
                              color: dataInDegrees ? 'black' : '#777771',
                            }}>
                            Grados
                          </span>
                        </button>
                      </div>

                      <table className="table table-bordered" style={{ borderRadius: 10 }} type="text/css">
                        <tbody>
                          <tr className="font-weight-bold" style={{ backgroundColor: '#F2F1E8' }}>
                            <td>Punto</td>
                            <td>Latitud</td>
                            <td>Longitud</td>
                            <td>Altitud</td>
                          </tr>
                          <tr>
                            <td>CENTRAL</td>
                            <td>{dataInDegrees ? centroidProcessed.deg.lat : centroidProcessed.dec.lat}</td>
                            <td>{dataInDegrees ? centroidProcessed.deg.lng : centroidProcessed.dec.lng}</td>
                            <td>{centroidProcessed.elevation}m</td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        style={{
                          overflowY: 'auto',
                          height: '220px',
                        }}>
                        <table className="table table-bordered" style={{ borderRadius: 10 }} type="text/css">
                          <tbody>
                            <tr className="font-weight-bold" style={{ backgroundColor: '#F2F1E8' }}>
                              <td>Punto</td>
                              <td>Latitud</td>
                              <td>Longitud</td>
                              <td>Altitud</td>
                            </tr>
                            {dataPoints.map((p, i) => {
                              return (
                                <tr key={i}>
                                  <td>{p.title}</td>
                                  <td>{parsePoint(p.lat, 7, true)}</td>
                                  <td>{parsePoint(p.lng, 7, true)}</td>
                                  <td>{p.elevation}m</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row mb-3" style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          <h4>Alertas automatizadas</h4>
                        </div>
                      </div>
                      <ParcelasAlertas uuid={uuid} hasPolygon={parcela.poligono !== null} />
                    </div>
                  </div>
                </Panel>
              </CardContainer>
            </BlockUi>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParcelaProfile;
