import { useEffect, useState } from 'react';
// import env from '../environment';
import { useCoopConfig } from '../components/content/coop-config';
import toDegreesMinutesAndSeconds from '../utils/toDegreesMinutesAndSeconds';
import parsePoint from '../utils/parsePoint';

const calculateAngle = (cx, cy, ex, ey) => {
  const dy = ey - cy;
  const dx = ex - cx;
  let theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
};

const useCroquisHook = ({ parcelaPolygon, centroid }) => {
  const zoom = 19;
  const { mostrarAreaCalculada } = useCoopConfig();
  const [center, setCenter] = useState({ lat: 16, lng: -92 });
  const [angle, setAngle] = useState(0);
  const [areaGMaps, setAreaGMaps] = useState(null);
  const [centroidProcessed, setCentroidProcessed] = useState({
    dec: { lat: 0, lng: 0 },
    deg: { lat: 0, lng: 0 },
    elevation: 0,
  });
  const [pointsOnMap, setPointsOnMap] = useState([]);
  const [pointsOnMapDegrees, setPointsOnMapDegrees] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);

  useEffect(() => {
    if (map != null && maps != null && maps.geometry) {
      if (parcelaPolygon != null) {
        const poligono = JSON.parse(parcelaPolygon);
        const poligono_data = poligono.coordinates[0].map(function(p) {
          return { lat: p[1], lng: p[0] };
        });

        const polygon = new maps.Polygon({
          paths: poligono_data,
          strokeColor: '#91B833',
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: '#f2f1e8',
          fillOpacity: 0.5,
          // editable: env.appEnv === 'local' || env.appEnv === 'dev',
          // draggable: env.appEnv === 'local' || env.appEnv === 'dev',
        });
        polygon.setMap(map);
        if (centroid && centroid.lat && centroid.lng) {
          setCenter({ lat: centroid.lat, lng: centroid.lng });
        } else {
          const latCenter = parseFloat(poligono.coordinates[0][0][1]);
          const lngCenter = parseFloat(poligono.coordinates[0][0][0]);
          setCenter({ lat: latCenter, lng: lngCenter });
        }

        // Google api area y convertir a Hectáreas
        const area = poligono_data ? maps.geometry.spherical.computeArea(poligono_data) / 10000 : 0;
        setAreaGMaps(area?.toFixed(2));

        // Distancia entre cada punto del polígono
        // ubicado entre los dos puntos
        const lengthsTemp = [];
        if (mostrarAreaCalculada()) {
          for (let i = 1; i < poligono_data.length; i++) {
            const curr = poligono_data[i];
            const prev = poligono_data[i - 1];
            const dist = maps.geometry.spherical.computeDistanceBetween(curr, prev);
            // No mostrar distancias muy pequeñas porque se enciman en el mapa
            if (dist > 4) {
              var midPoint = new maps.Point((curr.lat + prev.lat) / 2, (curr.lng + prev.lng) / 2);
              lengthsTemp.push({
                lat: midPoint.x,
                lng: midPoint.y,
                length: dist.toFixed(),
              });
            }
          }
        }
        setLengths(lengthsTemp);

        const poligono_data_filtrado = eliminarPuntosDuplicados(poligono_data);

        // Listado de puntos del polígono con su altitud
        const elevator = new maps.ElevationService();
        elevator
          .getElevationForLocations({ locations: poligono_data_filtrado })
          .then(response => {
            const points = [];
            poligono_data_filtrado.forEach((pnt, i) => {
              points.push({
                lat: pnt.lat,
                lng: pnt.lng,
                elevation: response.results[i].elevation.toFixed(),
                title: getAlphaOrder(i, poligono_data_filtrado.length),
              });
            });
            // Find Min and Max points for elevation direction
            const min = points.reduce((prev, curr) => (prev.elevation < curr.elevation ? prev : curr));
            const max = points.reduce((prev, curr) => (prev.elevation < curr.elevation ? curr : prev));
            setAngle(calculateAngle(max.lat, max.lng, min.lat, min.lng));
            setPointsOnMap(points);
            const pointsDegrees = points.map(pnt => {
              return {
                ...pnt,
                lat: toDegreesMinutesAndSeconds(pnt.lat, true),
                lng: toDegreesMinutesAndSeconds(pnt.lng),
              };
            });
            setPointsOnMapDegrees(pointsDegrees);
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log('error', error);
          });
        processCentroid(elevator, centroid.lat, centroid.lng);
      } else if (centroid && centroid.lat && centroid.lng) {
        const elevator = new maps.ElevationService();
        processCentroid(elevator, centroid.lat, centroid.lng);
      }
    }
  }, [map, maps, parcelaPolygon, centroid]);

  const renderMap = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  const processCentroid = (elevator, lat, lng) => {
    let elevCentroid = 0;
    elevator
      .getElevationForLocations({ locations: [{ lat: lat, lng: lng }] })
      .then(response => {
        if (response.results.length > 0 && !isNaN(response.results[0].elevation)) {
          elevCentroid = response.results[0].elevation.toFixed();
        }
        setCentroidProcessed({
          dec: {
            lat: parsePoint(lat),
            lng: parsePoint(lng),
          },
          deg: {
            lat: toDegreesMinutesAndSeconds(lat, true),
            lng: toDegreesMinutesAndSeconds(lng),
          },
          elevation: elevCentroid,
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });
  };

  // Listar A, B, C o listar 0A, 0B en caso de ser número más grande
  const getAlphaOrder = (n, total) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    if (total >= alphabet.length) {
      return Math.floor(0 + n / alphabet.length).toString() + alphabet[n % alphabet.length];
    }
    return alphabet[n];
  };

  const eliminarPuntosDuplicados = arr => {
    // eslint-disable-next-line eqeqeq
    return arr.filter((point, index, array) => array.findIndex(t => t.lat == point.lat && t.lng == point.lng) == index);
  };

  return {
    areaGMaps,
    renderMap,
    center,
    angle,
    pointsOnMap,
    pointsOnMapDegrees,
    centroidProcessed,
    lengths,
    zoom,
  };
};

export default useCroquisHook;
