import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import AnticiposXProductorList from './anticipos-x-productor-list.js';
import AnticipoList from './anticipos-list.js';

const AnticiposTables = () => {
  const [btnPorProductor, setBtnPorProductor] = useState(0);
  const coopConfig = useCoopConfig();
  const [ciclo, setCiclo] = useState({});
  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');

  useEffect(() => {
    getEtiquetaAnticipo();
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

  const setFilter = value => {
    setCiclo(value.target);
  };

  const isPorProductor = () => btnPorProductor % 2;
  const isPorAnticipo = () => !isPorProductor();

  const onClickPorAnticipo = () => {
    if (isPorAnticipo()) return;
    setBtnPorProductor(prev => prev + 1);
  };

  const onClickPorProductor = () => {
    if (isPorProductor()) return;
    setBtnPorProductor(prev => prev + 1);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">{etiquetaAnticipos}</li>
        </ol>
        <div className="row">
          <div className="btn-group mr-4" role="group" style={{ borderRadius: 1 }}>
            <button
              type="button"
              onClick={onClickPorAnticipo}
              className={'btn btn-light'}
              style={{
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                backgroundColor: isPorAnticipo() ? '#FFEED9' : 'transparent',
                borderWidth: 1,
                borderColor: '#d4d4d4',
              }}
              disabled={false}>
              Por {etiquetaAnticipos}
            </button>

            <button
              type="button"
              onClick={onClickPorProductor}
              className={'btn btn-light'}
              style={{
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                backgroundColor: isPorProductor() ? '#FFEED9' : 'transparent',
                borderWidth: 1,
                borderColor: '#d4d4d4',
              }}
              disabled={false}>
              Por productor
            </button>
          </div>
          <SelectCiclo
            setFilter={evt => {
              setFilter(evt);
            }}
            acopio
          />
        </div>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        {isPorAnticipo() ? (
          <AnticipoList ciclo={ciclo} update={btnPorProductor} etiquetaAnticipos={etiquetaAnticipos} />
        ) : (
          <AnticiposXProductorList ciclo={ciclo} update={btnPorProductor} etiquetaAnticipos={etiquetaAnticipos} />
        )}
      </CardContainer>
    </div>
  );
};

export default AnticiposTables;
