import React, { useContext, createContext, useState, useEffect } from 'react';
import { Api } from './../../app-controller.js';
import env from './../../environment.js';
import { useAuth } from '../../components/content/auth.jsx';
import transformWord from '../../utils/transformWord.js';
// import InicioResume from '../../pages/admin/inicio-resume.js';

const coopConfigContext = createContext();
// var globalConfig = {};
/**
 * ProvideCoopCopnfig wraps all our authentication methods
 */
export function ProvideCoopCopnfig({ children }) {
  const auth = useAuth();
  const [config, setConfig] = useState({});
  const [loadingConfig, setLoadingConfig] = useState(true);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = state => {
    if (auth.checkUser()) {
      return Api.fetch('acopios/catalogos/cooperativa_conf', state, 'GET')
        .then(response => {
          localStorage.setItem(`${env.appName}-coop-config`, JSON.stringify(response[0]));
          setConfig(response[0]);
          setLoadingConfig(false);
          return response[0];
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error loading configuration', e);
          setLoadingConfig(false);
        });
    }
  };

  const nuevaCuentaAcopio = () => {
    return Number(config.nueva_cuenta_acopio) === 1;
  };
  const estimacionCosecha = () => {
    return Number(config.estimacion_cosecha) === 1;
  };
  const adelantoEfectivo = () => {
    return Number(config.adelanto_efectivo) === 1;
  };
  const idCatCreditoPrecosecha = () => {
    return Number(config.id_cat_credito_precosecha) === 1;
  };
  const detalleIdsProductores = () => {
    return Number(config.detalle_ids_productores) === 1;
  };
  const detalleParcelas = () => {
    return Number(config.detalle_parcelas) === 1;
  };
  const mostrarCertificadora = () => {
    return Number(config.mostrar_certificadora) === 1;
  };
  const mostrarMuestreo = () => {
    return Number(config.mostrar_muestreo) === 1;
  };

  const mostrarTipo = () => {
    return Number(config.mostrar_tipos) === 1;
  };

  const mostrarLote = () => {
    return Number(config.mostrar_lotes) === 1;
  };

  const mostrarCostalera = () => {
    let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
    return Number(config.mostrar_costaleras) === 1;
  };

  const mostrarDescuento = () => {
    return Number(config.mostrar_descuento) === 1;
  };
  const mostrarAreaCalculada = () => {
    return Number(config.mostrar_area_calculada) === 1;
  };

  const mostrarDashboardRechazados = () => {
    return Number(config.mostrar_dashboard_rechazados) === 1;
  };

  const mostrarCreditoPrecosecha = (_coopConfig = false) => {
    let _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return Number(_config.id_cat_credito_precosecha) !== 1;
  };

  const etiquetaAnticipos = async (tipos = ['singular']) => {
    let etiqueta = new String(config.anticipos_etiqueta);
    return transformWord(etiqueta, tipos);
  };

  const etiquetaParcelas = async (tipos = ['singular']) => {
    let etiqueta = new String(config.parcelas_etiqueta);
    return transformWord(etiqueta, tipos);
  };

  const mostrarDashboardInicio = (_coopConfig = false) => {
    let _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return Number(_config.mostrar_dashboard_inicio) === 1;
  };

  const mostrarDashboardProductores = (_coopConfig = false) => {
    let _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return Number(_config.mostrar_dashboard_productores) === 1;
  };

  const mostrarPadronCompleto = (_coopConfig = false) => {
    let _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return Number(_config.mostrar_padron_completo) === 1;
  };

  const mostrarPadronConcentrado = (_coopConfig = false) => {
    let _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return Number(_config.mostrar_padron_concentrado) === 1;
  };

  const noMostrarPadronCambiarPath = (_coopConfig = false) => {
    let path = '/admin/padron/completo/list';
    let _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    if (Number(_config.mostrar_padron_completo) === 0) {
      return '/admin/padron/altas/list';
    }

    return path;
  };

  const acopioUnits = (_coopConfig = false) => {
    const _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return _config.acopio_units;
  };

  const currency = (_coopConfig = false) => {
    const _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return _config.currency;
  };

  const catCreditoPrecosecha = (_coopConfig = false) => {
    const _config = _coopConfig !== false ? JSON.parse(JSON.stringify(_coopConfig)) : config;
    return _config.cat_credito_precosecha;
  };

  const coopConfig = {
    config,
    getConfig,
    nuevaCuentaAcopio,
    estimacionCosecha,
    adelantoEfectivo,
    idCatCreditoPrecosecha,
    detalleIdsProductores,
    detalleParcelas,
    mostrarCertificadora,
    mostrarMuestreo,
    mostrarTipo,
    mostrarLote,
    mostrarCostalera,
    mostrarDescuento,
    mostrarAreaCalculada,
    mostrarDashboardRechazados,
    mostrarCreditoPrecosecha,
    etiquetaAnticipos,
    etiquetaParcelas,
    mostrarDashboardInicio,
    mostrarDashboardProductores,
    mostrarPadronCompleto,
    mostrarPadronConcentrado,
    noMostrarPadronCambiarPath,
    acopioUnits,
    currency,
    catCreditoPrecosecha,
    loadingConfig,
  };
  return <coopConfigContext.Provider value={coopConfig}>{children}</coopConfigContext.Provider>;
}

/**
 * useCoopConfig hook enables any component to get the current coopConfig state and re-render if it changes.
 **/

export function getRutaInicial(rutaInicial = '/dashboard') {
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  let _rutaInicial = rutaInicial;
  if (config !== null) {
    if (Number(config.mostrar_dashboard_inicio) === 0) {
      _rutaInicial = '/admin/cuentas';
    }

    if (Number(config.mostrar_dashboard_inicio) === 0 && Number(config.mostrar_dashboard_productores) === 0) {
      _rutaInicial = '/admin/cuentas/list';
    }
  }

  return _rutaInicial;
}

const operators = {
  '=': (a, b) => a == b,
  '==': (a, b) => a === b,
  '!=': (a, b) => a != b,
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '<': (a, b) => a < b,
  '<=': (a, b) => a <= b,
};

const runCondition = (condition, variable, value) => {
  if (condition in operators) {
    return operators[condition](Number(variable), Number(value));
  } else {
    // eslint-disable-next-line no-console
    console.error(`Condition ${condition} not found in coop-config.js logic.`, '\n', 'Check menu and route files to fix problem.');
  }
};

export function filtrarRutas(rutas) {
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  let _rutas = [];

  if (config === null) {
    _rutas = rutas;
  } else {
    for (let r = 0; r < rutas.length; r++) {
      if (typeof rutas[r].coopconfig !== 'undefined') {
        const condition = rutas[r].coopconfig.cond;
        if (runCondition(condition, config[rutas[r].coopconfig.variable], rutas[r].coopconfig.value)) {
          _rutas.push(rutas[r]);
        }
      } else {
        _rutas.push(rutas[r]);
      }
    }
  }

  return _rutas;
}

export function filtrarMenu(menus) {
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  let _menus = [];
  if (config === null) {
    _menus = menus;
  } else {
    for (let r = 0; r < menus.length; r++) {
      if (typeof menus[r].coopconfig !== 'undefined') {
        if (typeof menus[r].coopconfig.variable !== 'undefined') {
          const condition = menus[r].coopconfig.cond;
          if (runCondition(condition, config[menus[r].coopconfig.variable], menus[r].coopconfig.value)) {
            _menus.push(menus[r]);
          }
        } else {
          _menus.push(menus[r]);
        }
      } else {
        _menus.push(menus[r]);
      }
    }
  }
  return _menus;
}

export function showMenu(submenu) {
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  let show = true;
  if (config !== null) {
    if (typeof submenu.coopconfig !== 'undefined') {
      if (typeof submenu.coopconfig.variable !== 'undefined') {
        const condition = submenu.coopconfig.cond;
        if (!runCondition(condition, config[submenu.coopconfig.variable], submenu.coopconfig.value)) {
          show = false;
        }
      }
    }
  }
  return show;
}

export function transformMenu(submenu) {
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    if (typeof submenu.coopconfig2 !== 'undefined') {
      try {
        switch (submenu.coopconfig2) {
          case 'mostrarCreditoPrecosecha':
            submenu.title = transformWord(config.anticipos_etiqueta, ['plural', 'capitalize']);
            break;
          case 'noMostrarPadronCambiarPath':
            if (Number(config.mostrar_padron_completo) === 0) {
              submenu.path = '/admin/padron/concentrado/list';
            }

            if (Number(config.mostrar_padron_concentrado) === 0 && Number(config.mostrar_padron_concentrado) === 0) {
              submenu.path = '/admin/padron/altas/list';
            }
            break;
          case 'noMostrarProductoresDashboardCambiarPath':
            if (Number(config.usa_sic) === 0) {
              submenu.path = '/admin/cuentas/list';
            }
            break;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }
  return submenu;
}

export function getIdCatCreditoPrecosecha() {
  let id_cat_credito_precosecha = 1;
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    id_cat_credito_precosecha = config.id_cat_credito_precosecha;
  }

  return id_cat_credito_precosecha;
}

export function getUsaSic() {
  let usa_sic = 1;
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    usa_sic = config.usa_sic;
  }
  return Number(usa_sic);
}

export function getUsaEstimacionManual() {
  let usa_estimacion_manual = 1;
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    usa_estimacion_manual = config.usa_estimacion_manual;
  }
  return Number(usa_estimacion_manual);
}

export function usaCentrosAcopios() {
  let usa_centros_acopios = 1;
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    usa_centros_acopios = config.usa_centros_acopios;
  }
  return Number(usa_centros_acopios);
}

export function etiquetaAnticipos(tipos = ['singular', 'capitalize']) {
  let etiqueta = 'crédito';
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    etiqueta = config.anticipos_etiqueta;
  }
  return transformWord(etiqueta, tipos);
}

export function precioPorKgEditable() {
  let valor = false;
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    valor = Number(config.precio_por_kg_editable) === 1;
  }
  return valor;
}

export function precioPorKgPrecargado() {
  let valor = false;
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    valor = Number(config.precio_por_kg_precargado) === 1;
  }
  return valor;
}

export function precioPorKgPorProductor() {
  let valor = false;
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    valor = Number(config.precio_por_kg_por_productor) === 1;
  }
  return valor;
}

export function etiquetaParcelas(tipos = ['singular', 'capitalize']) {
  let etiqueta = 'parcela';
  let config = JSON.parse(localStorage.getItem(`${env.appName}-coop-config`));
  if (config !== null) {
    etiqueta = config.parcelas_etiqueta;
  }
  return transformWord(etiqueta, tipos);
}

export function useCoopConfig() {
  return useContext(coopConfigContext);
}
