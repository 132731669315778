/**
 * get object with label and color for estatus of debt
 * @param {number} saldo
 * @returns {{ label: {string}, color: {string} }}
 */
const getEstatus = saldo => {
  if (Number(saldo) >= 0.01) {
    return { label: 'Vigente', color: '#e74c3c' };
  }
  return { label: 'Pasado', color: '#27ae60' };
};

export default getEstatus;
