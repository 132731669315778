import Api from '../../api/global.service';
import Swal from 'sweetalert2';

/**
 * Delete croquis for parcela
 * @param {string} uuid
 * @param {window} window
 */
const deleteCroquis = (uuid, window) => {
  let url;
  Swal.fire({
    icon: 'info',
    title: '',
    showDenyButton: true,
    showCancelButton: true,
    text: `¿Deseas eliminar el croquis actual y utilizar el anterior, o quieres dejar esta parcela sin croquis?`,
    confirmButtonText: 'Eliminar actual y usar el anterior si existe',
    denyButtonText: 'Eliminar croquis',
    cancelButtonText: 'Cancelar',
  }).then(result => {
    if (result.isConfirmed) {
      url = 'eliminarultimocroquis';
    } else if (result.isDenied) {
      url = 'eliminarcroquis';
    } else if (result.isDismissed) {
      return '';
    }
    Api.delete(url, uuid)
      .then(response => {
        const { message } = response.data;
        Swal.fire({
          icon: 'success',
          title: 'Croquis eliminado',
          text: message,
          confirmButtonText: 'Continuar',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.href = '/admin/parcelas/perfil/' + uuid;
          }
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        Swal.fire({
          icon: 'error',
          title: '',
          text: error.response?.data?.message,
        });
      });
  });
};

export default deleteCroquis;
