import env from '../environment';

const getBackgroundColor = (i, j) => {
  if (i == 1) {
    return 'rgb(247, 239, 231)';
  } else {
    switch (j) {
      case 1:
        return '#91918A';
      case 3:
        return '#91918A';
      case 5:
        return '#91918A';
      case 7:
        return '#91918A';
      default:
        return env.colors.neutral_600;
    }
  }
};

export default getBackgroundColor;
