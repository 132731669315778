import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import ModalCreditoProductor from '../../components/modal/modal-credito-productor.jsx';
import ModalCreditoProductorAbono from '../../components/modal/modal-credito-productor-abono.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';
import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import { subscribe, unsubscribe } from '../../components/events';
import { useCoopConfig, getIdCatCreditoPrecosecha } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import DebtCalculator from '../../nuup/utils/debtCalculator.js';
import xlsx from 'json-as-xlsx';
import moment from 'moment/moment.js';
import LinkDownload from '../../components/link/link-download.jsx';
import { Colors } from '../../constants/Colors.js';
import getEstatus from '../../nuup/utils/getStatusDebtColor.js';
import getBackgroundColor from '../../utils/getBackgroundColor.js';
import getColor from '../../utils/getColor.js';
import insertIf from '../../utils/insertIf.js';
import isEmpty from '../../utils/isEmpty.js';

const AnticipoList = ({ update, ciclo, etiquetaAnticipos }) => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const dialog = React.createRef();
  const dialogAbono = React.createRef();
  const [selectedData, setSelectedData] = useState({});

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        id: 'acopio_uuid',
        columns: [
          {
            Header: '',
            id: 'acopio_uuid',
            accessor: 'x',
            width: 25,
            Cell: ({ row }) => {
              return (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="entregas"
                    type="checkbox"
                    style={{ width: 20, height: 20, accentColor: Colors.primary_600 }}
                    value={row.original.acopio_uuid}
                    onChange={e => {
                      setSelectedData(() => {
                        return e.target.checked ? row.original : {};
                      });
                    }}
                    checked={(selectedData?.uuid === row.original.uuid) === true}
                  />
                </div>
              );
            },
          },
          {
            Header: 'CLAVE',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            width: 100,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color} />;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: transformWord(etiquetaAnticipos, ['plural', 'uppercase']),
        columns: [
          {
            Header: 'FECHA DESEMBOLSO',
            accessor: 'fecha_ejecucion',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => <LabelDate value={value}></LabelDate>,
          },
          {
            Header: 'ID ' + transformWord(etiquetaAnticipos, ['singular', 'uppercase']),
            accessor: 'folio',
            sortable: true,
            minWidth: 80,
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            Cell: ({ row }) => {
              return <LinkDownload url={`recibos/anticipo/descargar/${row.original.uuid}`} label="Descargar" icon={env.images.icon.pdf} />;
            },
          },
          {
            Header: 'IMPORTE TOTAL',
            accessor: 'total',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: `CAPITAL DESEMBOLSADO`,
            accessor: 'cantidad_anticipo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value / 100} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: 'INTERÉS ACUMULADO',
            accessor: 'total_interes',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={value === 0 ? 0 : 2} />,
          },

          {
            Header: 'TASA INTERÉS',
            accessor: 'tasa_interes',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => <LabelNumber value={value / 100} digits={value === 0 ? 0 : 2} suffix={'%'} />,
          },
          {
            Header: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>,
          },
          {
            Header: 'COMISIÓN',
            accessor: 'comision_efectivo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => (
              <LabelNumber
                value={row.original.anticipo_pagos.reduce(
                  (sum, item) =>
                    sum + (Number(item.comision_efectivo) > 0) ? Number(item.comision_efectivo) / 100 : Number(item.comision_efectivo),
                  0
                )}
                digits={value === 0 ? 0 : 2}
                currency={false}
                currencyLabel={coopConfig.currency()?.symbol}
              />
            ),
          },
          {
            Header: 'SALDO',
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} digits={2} currencyLabel={coopConfig.currency()?.symbol} />,
          },
          {
            Header: 'DESCRIPCIÓN',
            accessor: 'descripcion',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus',
            sortable: true,
            minWidth: 50,
            Cell: ({ row, value }) => <LabelColor label={value} color={row.original.estatus_color} />,
          },
          {
            Header: 'REGISTRO',
            accessor: 'registro',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              return (
                <p className="text-left" style={{ margin: 0 }}>
                  Registrado {value ? `por ${value}` : null} el {row.original.fecha_ejecucion}
                </p>
              );
            },
          },
        ],
      },
    ],
    [etiquetaAnticipos, selectedData]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'productor_clave_tx', label: 'CLAVE' },
      ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { label: 'CÓDIGO', value: 'productor_codigo_tx' }),
      { value: 3, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 4, target: 'localidad', label: 'LOCALIDAD' },
      { value: 5, target: 'region', label: 'REGIÓN' },
      { value: 6, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 7, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      { value: 8, target: 'fecha_ejecucion', label: 'FECHA DESEMBOLSO' },
      { value: 9, target: 'folio', label: 'ID ' + transformWord(etiquetaAnticipos, ['singular', 'uppercase']) },
      ...insertIf(getIdCatCreditoPrecosecha() > 2, { value: 10, target: 'total', label: 'IMPORTE TOTAL' }),
      { value: 11, target: 'cantidad_anticipo', label: 'CAPITAL DESEMBOLSADO' },
      ...insertIf(getIdCatCreditoPrecosecha() > 2, { value: 12, target: 'total_interes', label: 'INTERÉS ACUMULADO' }),
      ...insertIf(getIdCatCreditoPrecosecha() > 2, { value: 13, target: 'tasa_interes', label: 'TASA INTERÉS' }),
      {
        value: 14,
        target: 'total_anticipos_recuperados',
        label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
      },
      { value: 15, target: 'comision_efectivo', label: 'COMISIÓN' },
      { value: 16, target: 'total_saldo', label: 'SALDO' },
      { value: 17, target: 'estatus', label: 'ESTATUS' },
      { value: 18, target: 'registro', label: 'REGISTRO' },
    ],
    [update, etiquetaAnticipos]
  );

  useEffect(() => {
    if (ciclo.id) {
      updateData(ciclo.id);
      dialog.current.setIdCiclo(ciclo.id);
    }
  }, [ciclo, update]);

  useEffect(() => {
    subscribe('Anticipo::onSave', e => handleSave(e));
    subscribe('AnticipoPago::onSave', e => handleSavePago(e));
    return () => {
      unsubscribe('Anticipo::onSave');
      unsubscribe('AnticipoPago::onSave');
    };
  }, []);

  const updateData = id_ciclo => {
    setBlocking(true);
    const interesCoop = coopConfig.catCreditoPrecosecha()?.interes;
    Api.Acopio.search('anticipos', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        const updatedData = response.data.map(anticipo => {
          const total_anticipos_recuperados = anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.cantidad_pago / 100, 0);

          // RETURN DATA WITH DEBT CALCULATION
          if (getIdCatCreditoPrecosecha() > 2) {
            const interes = anticipo.tasa_interes && +anticipo.tasa_interes > 0 ? +anticipo.tasa_interes : +interesCoop;
            const debtCalc = new DebtCalculator({
              credito: anticipo,
              interestRate: interes,
              pagos: anticipo.anticipo_pagos,
              stringDate: null,
            });
            const calc = debtCalc.getDebt();
            const estatus = getEstatus(calc.debtToday.pendingCapital / 100);
            return {
              ...anticipo,
              anticipo_id: anticipo.id,
              total_anticipos_recuperados,
              total_saldo: calc.debtToday.pendingCapital / 100,
              total: +calc.debtToday.totalInterest / 100 + +anticipo.cantidad_anticipo / 100,
              total_interes: calc.debtToday.totalInterest / 100,
              estatus: estatus.label,
              estatus_color: estatus.color,
              tasa_interes: interes,
            };
          }
          // RETURN DATA WITH NO INTEREST RATE
          const estatus = getEstatus(+anticipo.cantidad_anticipo / 100 - +total_anticipos_recuperados);

          return {
            ...anticipo,
            anticipo_id: anticipo.id,
            total_anticipos_recuperados,
            total: +anticipo.cantidad_anticipo / 100,
            total_interes: 0,
            total_saldo: +anticipo.cantidad_anticipo / 100 - +total_anticipos_recuperados,
            estatus: estatus.label,
            estatus_color: estatus.color,
            comision_efectivo: 999,
          };
        });
        setData(updatedData);
        setOriginalData(updatedData);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const exportarAnticipos = () => {
    let xlsData1 = [
      {
        sheet: 'Anticipos',
        columns: [
          { label: 'CLAVE', value: 'productor_clave_tx' },
          ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { label: 'CÓDIGO', value: 'productor_codigo_tx' }),
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          { label: 'FECHA DESEMBOLSO', value: 'fecha_ejecucion' },
          { label: 'ID ' + transformWord(etiquetaAnticipos, ['singular', 'uppercase']), value: 'folio' },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'IMPORTE TOTAL',
            value: 'total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          { label: 'CAPITAL DESEMBOLSADO', value: row => +row.cantidad_anticipo / 100, format: `${coopConfig.currency()?.symbol}#,##0.00` },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'INTERÉS ACUMULADO',
            value: 'total_interes',
            format: `${coopConfig.currency()?.symbol}0.00`,
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 2, { label: 'TASA INTERÉS', value: row => +row.tasa_interes / 10000, format: '0.00%' }),
          {
            label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADOS',
            value: row => (isNaN(row.total_anticipos_recuperados) ? 0 : +row.total_anticipos_recuperados),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'COMISIÓN',
            value: row =>
              row.anticipo_pagos.reduce(
                (sum, item) =>
                  sum + (Number(item.comision_efectivo) > 0) ? Number(item.comision_efectivo) / 100 : Number(item.comision_efectivo),
                0
              ),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'SALDO',
            value: row => (row.total_saldo < 0.01 && row.total_saldo > -0.01 ? 0 : row.total_saldo),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          { label: 'DESCRIPCIÓN', value: 'descripcion' },
          { label: 'ESTATUS', value: 'estatus' },
          { label: 'REGISTRO', value: row => `Registrado ${row.registro ? 'por ' + row.registro : ''} el ${row.fecha_ejecucion}` },
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `Anticipos_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  const handleSave = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el crédito ${event.detail.folio}.`,
    });
    setSelectedData({});
    updateData(event.detail.id_ciclos);
  };

  const handleSavePago = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el abono al credito.`,
    });
    setSelectedData({});
    updateData(event.detail.id_ciclo);
  };

  const openModalAbono = () => {
    dialogAbono.current.setData(selectedData);
    dialogAbono.current.setIdAnticipo(selectedData.anticipo_id);
    dialogAbono.current.setIdCiclo(selectedData.id_ciclo);
    dialogAbono.current.show();
  };

  const eliminarEntrega = () => {
    if (selectedData.anticipo_pagos?.length > 0) {
      Swal.fire({
        title: `No se puede eliminar el ${etiquetaAnticipos} ${selectedData.folio} pues ya tiene pagos. Por favor contacta al equipo de Sirio para más ayuda.`,
        icon: 'info',
      });
    } else {
      Swal.fire({
        title: `El ${etiquetaAnticipos} ${selectedData.folio}  se eliminará`,
        text: `de ${transformWord(selectedData.productor_nombre, ['uppercase'])}`,
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        confirmButtonColor: '#FF5733',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#f4f4f4',
      }).then(result => {
        if (result.isConfirmed) {
          Api.Acopio.delete('anticipos', selectedData.uuid)
            .then(response => {
              const { success, message } = response.data;
              if (success === 1) {
                Swal.fire({
                  icon: 'success',
                  title: '',
                  text: `Se eliminó el ${etiquetaAnticipos}`,
                  confirmButtonText: 'Continuar',
                }).then(result => {
                  if (result.isConfirmed) {
                    window.location.href = `/admin/acopio/anticipos_por_productor/list`;
                  }
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: '',
                  text: message,
                  showDenyButton: true,
                  denyButtonText: `Regresar a Listado`,
                }).then(result => {
                  if (result.isDenied) {
                    // eslint-disable-next-line no-console
                    console.log('response.data', response.data);
                  }
                });
              }
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.log('error', error);
              Swal.fire({
                icon: 'error',
                title: '',
                text: error.response?.data?.message,
              });
            });
        }
      });
    }
  };

  return (
    <>
      <ModalCreditoProductor
        ref={dialog}
        title={'Registrar ' + transformWord(etiquetaAnticipos, ['singular']) + ' precosecha'}></ModalCreditoProductor>
      <ModalCreditoProductorAbono ref={dialogAbono} title="Abono de efectivo"></ModalCreditoProductorAbono>
      <div className="row mb-2">
        <div className="col-5 d-flex">
          <SearchColumn
            data={originalData}
            columns={searchColumns}
            width="170%"
            setDataFilter={evt => {
              setDataFilter(evt);
            }}></SearchColumn>
        </div>

        <div className="col-md-5 d-flex justify-content-around mb-3">
          <button
            onClick={() => dialog.current.show()}
            type="button"
            className="btn btn-sirio"
            style={{
              borderRadius: '20px 20px 20px 20px',
              color: '#333',
              fontWeight: 'normal',
              fontSize: 16,
              flexGrow: 1,
              flexBasis: 'auto',
              backgroundColor: '#FBB03F',
            }}>
            <i className="fa fa-plus pr-2"></i> {'Nuevo ' + transformWord(etiquetaAnticipos, ['singular'])}
          </button>

          <button
            onClick={() => openModalAbono()}
            type="button"
            className="btn btn-outline-secondary"
            style={{
              borderRadius: '20px 20px 20px 20px',
              marginLeft: 10,
              flexGrow: 1,
              flexBasis: 'auto',
              opacity: !isEmpty(selectedData) && Number(selectedData?.total_saldo) >= 0.01 ? 1 : 0.4,
            }}
            disabled={isEmpty(selectedData) || Number(selectedData?.total_saldo) < 0.01}>
            <i className="fa fa-plus pr-2"></i> Registrar abono efectivo
          </button>

          <button
            onClick={eliminarEntrega}
            type="button"
            className="btn"
            style={{
              borderColor: 'red',
              borderRadius: '20px 20px 20px 20px',
              marginLeft: 10,
              flexGrow: 1,
              flexBasis: 'auto',
              opacity: !isEmpty(selectedData) ? 1 : 0.25,
            }}
            disabled={isEmpty(selectedData)}>
            <span style={{ color: 'red' }}>
              <i className="fa fa-trash pr-2"></i> {' Eliminar'}
            </span>
          </button>
        </div>

        <div className="col-2">
          {ciclo?.uuid && auth.hasPermission('ACOPIOS_ANTICIPOS_X_PRODUCTOR_EXPORTAR') && (
            <div className="d-flex justify-content-end">
              <button onClick={() => exportarAnticipos()} className={`btn`} type="button">
                <img src={env.images.icon.xls} className="pr-1" />
                <p style={{ color: '#789D16' }}>Descargar Anticipos</p>
              </button>
            </div>
          )}
        </div>
      </div>

      <NoPagination
        blocking={blocking}
        selected="id"
        columns={columns}
        data={data}
        fnBackgroundColor={getBackgroundColor}
        fnColor={getColor}></NoPagination>
    </>
  );
};

export default AnticipoList;
