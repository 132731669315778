import Swal from 'sweetalert2';
import env from '../../environment';

/**
 * Open image or show sweet alert
 * @param {string} croquisCaptura url string for image
 * @param {window} window
 */
const abrirDibujoCroquis = (croquisCaptura, window) => {
  if (croquisCaptura == env.noImage) {
    Swal.fire({
      text: 'Esta parcela no tiene una imagen de croquis',
      icon: 'warning',
      confirmButtonText: 'Cerrar',
    });
  } else {
    window.open(`${env.apiURL}/${croquisCaptura}`, '_blank');
  }
};

export default abrirDibujoCroquis;
