/**
 * Returns a string with the degrees, minutes seconds representation of a latitude or longitude position
 * @param {number} coordinate latitud or langitue number
 * @param {boolean} isLatitude check if is latitud to return N S or W S if longitude
 * @returns {string}
 */
const toDegreesMinutesAndSeconds = (coordinate, isLatitude) => {
  if (isNaN(coordinate)) return '--';
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
  let position;
  if (isLatitude) {
    position = coordinate < 0 ? 'S' : 'N';
  } else {
    position = coordinate < 0 ? 'W' : 'E';
  }

  return degrees + '° ' + minutes + `' ` + seconds + `" ` + position;
};

export default toDegreesMinutesAndSeconds;
