import React from 'react';
import env from '../../environment';

export default function Loading() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignIitems: 'center',
        justifyContent: 'center',
        borderWidth: '1px',
        borderColor: 'red',
      }}>
      <img
        src={env.images.spinners.building}
        style={{
          height: '75px',
          position: 'absolute',
          bottom: '25px',
          left: '250px',
        }}
      />
    </div>
  );
}
