

const formatMoneyNumber = (number, precision = 2) => {
    let _number = parseFloat(number).toFixed(precision);
    _number = _number.toLocaleString("en-US");
    return _number;
}

export const formatNumber = (number, precision = 2) => {
    let _number = parseFloat(number).toFixed(precision);
    return _number;
}

export default formatMoneyNumber;
