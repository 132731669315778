import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import { Link, useParams } from 'react-router-dom';
import { Panel } from '../../components/panel/panel.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import BlockUi from 'react-block-ui';
import GoogleMapReact from 'google-map-react';
import env from './../../environment.js';
import BackButton from '../../components/link/back-button.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import MapText from '../../components/card/map-text.jsx';
import generalCatchErrorsModal from '../../components/modal/modal-error';
import Swal from 'sweetalert2';
import CardTitleParcela from '../../components/card/card-title-parcela.jsx';
import useCroquisEditHook from '../../hooks/useCroquisEditHook.jsx';
import EditLink from '../../components/link/edit-link.jsx';
import deleteCroquis from '../../nuup/utils/deleteCroquis.js';
import abrirDibujoCroquis from '../../nuup/utils/openImageCroquis.js';

const ParcelaEditCroquis = () => {
  const { uuid } = useParams();
  const [blocking, setBlocking] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  const [mapType, setMapType] = useState('satellite');
  const [parcela, setParcela] = useState({});
  const [parcelaData, setParcelaData] = useState({});
  const [intersecciones, setIntersecciones] = useState([]);
  const [croquisCaptura, setCroquisCaptura] = useState(null);
  // const history = useHistory();
  const {
    areaGMaps,
    areaGMapsOG,
    renderMap,
    center,
    pointsOnMap,
    lengths,
    zoom,
    undoChange,
    redoChange,
    getPath,
    // historyIndex,
    // historyArr,
    hideShowIntersections,
    intersectPolygons,
  } = useCroquisEditHook(parcelaData, intersecciones);

  useEffect(() => {
    // window.addEventListener('resize', function() {
    //   setWidth(window.innerWidth);
    // });
    if (uuid != null) {
      setBlocking(true);
      Api.get('parcela_profile', uuid)
        .then(response => {
          setParcela(response.data);
          let cent;
          if (response?.data?.poligono) {
            cent = { lat: parseFloat(response?.data?.centroide_pol_lat), lng: parseFloat(response?.data?.centroide_pol_lng) };
          } else if (!isNaN(response?.data?.centroide_lat) && !isNaN(response?.data?.centroide_lat)) {
            cent = { lat: parseFloat(response?.data?.centroide_lat), lng: parseFloat(response?.data?.centroide_lng) };
          }
          setParcelaData({
            parcelaPolygon: response?.data?.poligono,
            centroid: cent,
          });
          setBlocking(false);
        })
        .catch(e => {
          generalCatchErrorsModal(e.response?.status, '/admin/parcelas/list');
          setBlocking(false);
        });
      Api.get('parcelas_croquis_intersecciones', uuid)
        .then(response => {
          setIntersecciones(response.data);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error: ', e);
        });
      Api.get('parcelas_croquis_captura', uuid)
        .then(response => {
          if (response.data && response.data.length > 0) {
            setCroquisCaptura(response.data);
          } else {
            setCroquisCaptura(env.noImage);
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('e', e);
        });
    }
  }, []);

  const onSubmit = () => {
    setBlocking(true);
    const payload = {
      uuid,
      croquis: getPath(),
    };

    Api.create(`update_croquis`, payload)
      .then(response => {
        const { success, message, data } = response.data;
        if (success === 1) {
          if (data.croquis?.alertas?.length > 0) {
            const alertas = data.croquis.alertas.map(alerta => alerta.titulo).join(', ');
            Swal.fire({
              icon: 'info',
              title: '',
              showDenyButton: true,
              text: `El Croquis de ${data.nombre} fue editado correctamente, sin embargo se detectaron alertas: ${alertas}`,
              confirmButtonText: 'Regresar a Perfil de Parcela',
              denyButtonText: 'Volver a editar',
            }).then(result => {
              if (result.isConfirmed) {
                window.location.href = `/admin/parcelas/perfil/${uuid}`;
              } else if (result.isDenied) {
                window.location.href = `/admin/parcelas/perfil/editcroquis/${uuid}`;
              }
            });
          } else {
            Swal.fire({
              icon: 'success',
              title: '',
              text: `Croquis de ${data.nombre} editado correctamente`,
              confirmButtonText: 'Regresar a Perfil de Parcela',
            }).then(result => {
              if (result.isConfirmed) {
                window.location.href = `/admin/parcelas/perfil/${uuid}`;
              }
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: message,
            showDenyButton: true,
            denyButtonText: `Regresar a Listado`,
          }).then(result => {
            if (result.isDenied) {
              // eslint-disable-next-line no-console
              console.log('response.data', response.data);
            }
          });
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        Swal.fire({
          icon: 'error',
          title: '',
          text: error.response?.data?.message,
        });
      });
  };

  return (
    <>
      <div>
        <Breadcrumb>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/admin/parcelas">Parcelas</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/parcelas/list">Listado parcelas</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/admin/parcelas/perfil/${uuid}`}>Perfil parcela</Link>
            </li>
            <li className="breadcrumb-item active">Ediicón Croquis</li>
          </ol>
          <BackButton goBackDefault="/admin/parcelas/list" />
        </Breadcrumb>
        <div className="row justify-content-end mb-2">
          <div className="col-4">
            <FormSelectMaterial
              id="tipoMapa"
              error={false}
              label="Tipo Mapa"
              value={mapType}
              onChange={v => setMapType(v)}
              height={36}
              options={[
                { value: 'satellite', label: 'Satelital' },
                { value: 'terrain', label: 'Terreno' },
              ]}
              showAll={false}
            />
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-xl-12">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer>
                <Panel>
                  <div className="row pb-4">
                    <div className="col-xl-4">
                      <h5>CROQUIS DE PARCELA</h5>
                      <div className="row mb-3">
                        <CardTitleParcela
                          cuenta_uuid={parcela.cuenta_uuid}
                          cuenta_name={parcela.cuenta}
                          parcela_name={parcela.nombre}
                          localidad={parcela.localidad}
                          municipio={parcela.municipio}
                        />
                      </div>
                      <div className="row align-items-center mb-3">
                        <img src={env.images.icon.area} style={{ height: '60px' }} />
                        <h4 className="font-lato-normal-400" style={{ paddingBottom: '8px' }}>
                          ÁREA:
                        </h4>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <p className="font-lato-normal-500">Reportada:</p>
                        </div>
                        <div className="col-8">
                          <p className="font-lato-normal-500" style={{ padding: '6px 12px' }}>
                            {(parcela.superficie || 0).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{' '}
                            has
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <p className="font-lato-normal-500">Original:</p>
                        </div>
                        <div className="col-8">
                          <p className="font-lato-normal-500" style={{ padding: '6px 12px' }}>
                            {(areaGMapsOG || 0).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{' '}
                            has
                          </p>
                        </div>
                      </div>

                      <hr style={{ marginTop: '0' }} />

                      <div className="row mb-5">
                        <div className="col-4">
                          <p className="font-lato-normal-500">Área actual:</p>
                        </div>
                        <div className="col-8">
                          <p className="font-lato-normal-500">
                            <span style={{ backgroundColor: '#E4E3DB', borderRadius: 4, padding: '6px 12px' }}>
                              {(areaGMaps || 0).toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{' '}
                              has
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-5" style={{ paddingRight: 0 }}>
                          <div className="row align-items-center mb-3">
                            <img src={env.images.icon.distances} style={{ height: '28px' }} />
                            <h4 className="font-lato-normal-400" style={{ paddingLeft: '10px', marginBottom: '2px' }}>
                              DISTANCIAS:
                            </h4>
                          </div>
                          <div>
                            {lengths.map((item, index) => (
                              <div key={index} className="row mb-1">
                                <div className="col-4">
                                  <p className="font-lato-normal-500">{item?.title}:</p>
                                </div>
                                <div className="col-8">
                                  <p className="font-lato-normal-500">
                                    <span style={{ backgroundColor: '#E4E3DB', borderRadius: 4, padding: '6px 12px' }}>
                                      {(item?.length || 0).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}{' '}
                                      m
                                    </span>
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-7" style={{ paddingRight: 0 }}>
                          {intersectPolygons.length > 0 ? (
                            <div
                              style={
                                {
                                  // borderLeft: '1px solid #E4E3DB'
                                }
                              }>
                              <div className="row align-items-center mb-3">
                                <h4
                                  className="font-lato-normal-400"
                                  style={{ paddingLeft: '10px', marginBottom: '2px', marginLeft: '28px' }}>
                                  COLINDANTES:
                                </h4>
                              </div>
                              <div
                                style={{
                                  paddingLeft: '10px',
                                }}>
                                {intersectPolygons.map(({ polygon, visible }, index) => (
                                  <div key={index} className="row mb-2" style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                                      <button
                                        type="button"
                                        onClick={() => hideShowIntersections(polygon.id)}
                                        className={'btn btn-light '}
                                        style={{
                                          padding: '0px 4px',
                                        }}
                                        disabled={false}>
                                        <i
                                          className={visible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                                          style={{
                                            color: visible ? polygon.color : '#777771',
                                          }}
                                        />
                                      </button>
                                      <EditLink
                                        onClickEdit={() => (window.location.href = `/admin/parcelas/perfil/editcroquis/${polygon.uuid}`)}>
                                        <p className="font-lato-normal-500" style={{ marginBottom: '0px', marginLeft: '6px' }}>
                                          {polygon?.nombre} de {polygon?.cuenta}
                                        </p>
                                      </EditLink>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 mb-2">
                      <div className="row mb-3">
                        <div className="col-5"></div>
                        <div className="col-1">
                          <button
                            // disabled={historyIndex <= 0}
                            onClick={undoChange}
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{ borderRadius: '20px' }}>
                            <i className="fa fa-undo"></i>
                          </button>
                        </div>
                        <div className="col-1">
                          <button
                            // disabled={!(historyIndex < historyArr.length - 1)}
                            onClick={redoChange}
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{ borderRadius: '20px' }}>
                            <i className="fa fa-redo"></i>
                          </button>
                        </div>
                        <div className="col-5"></div>
                      </div>
                      <div className="map-rounded" style={{ height: '600px', width: '100%' }}>
                        {parcela.poligono ? (
                          <>
                            {/* <TerrainDirection angle={angle} color={mapType === 'satellite' ? 'white' : 'black'} /> */}
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: env.googleApiKey,
                                libraries: ['geometry'],
                              }}
                              options={() => ({ mapTypeId: mapType })}
                              center={center}
                              zoom={zoom}
                              yesIWantToUseGoogleMapApiInternals
                              onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                              {pointsOnMap.map((it, i) => (
                                <MapText
                                  lat={it?.lat}
                                  lng={it?.lng}
                                  key={i}
                                  text={it?.title}
                                  style={{ color: mapType === 'satellite' ? 'white' : 'black' }}
                                />
                              ))}
                            </GoogleMapReact>
                          </>
                        ) : null}
                      </div>
                      <div className="row mt-5">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                          }}>
                          <button
                            type="button"
                            onClick={() => abrirDibujoCroquis(croquisCaptura, window)}
                            className="btn btn-outline"
                            style={{
                              borderRadius: '20px 20px 20px 20px',
                              minWidth: 60,
                              borderWidth: 1,
                              borderColor: env.colors.neutral_700,
                            }}>
                            <span style={{ color: env.colors.neutral_700 }}>Ver dibujo croquis</span>
                          </button>
                          <button
                            onClick={onSubmit}
                            type="button"
                            className="btn btn-outline btn-sirio"
                            style={{ borderRadius: '20px 20px 20px 20px', paddingLeft: 24, paddingRight: 24 }}>
                            Guardar cambios
                          </button>
                          <button
                            type="button"
                            onClick={() => deleteCroquis(uuid, window)}
                            className="btn btn-outline"
                            style={{ borderRadius: '20px 20px 20px 20px', minWidth: 60, borderWidth: 1, borderColor: env.colors.error }}>
                            <i className="fa fa-trash" style={{ color: env.colors.error, marginRight: 6 }}></i>
                            <span style={{ color: env.colors.error }}>Eliminar Croquis</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </CardContainer>
            </BlockUi>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParcelaEditCroquis;
