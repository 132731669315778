import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../../components/card/card-container.jsx';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../../components/label/label-category.jsx';
import LabelNumber from '../../../components/label/label-number.jsx';
import ModalAsignarLote from './modals/modal-asignar-lote.jsx';
import ModalNuevoLote from './modals/modal-nuevo-lote.jsx';
import ModalInfoLote from './modals/modal-info-lote.jsx';
import Api from '../../../api/global.service';
import { useAuth } from '../../../components/content/auth.jsx';
import env from '../../../environment.js';
import Swal from 'sweetalert2';

import NoPagination from '../../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../../components/select/select-ciclo.jsx';

import { subscribe, unsubscribe } from '../../../components/events';
import LinkDownload from '../../../components/link/link-download.jsx';
import { useHistory } from 'react-router-dom';
import FormSelectMaterial from '../../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import CheckboxList from '../../../components/forms/checkbox-list.jsx';
import './entregas-x-lote.css';
import ModalCambiarEstado from './modals/modal-cambiar-estado.jsx';
import LabelEstatus from '../../../components/label/label-estatus.jsx';
import LotesGraficaEstatus from './components/lotes-grafica-estatus.js';
import LotesExport from './components/lotes-export.js';
import LotesEntregasExport from './components/lotes-entregas-export.js';
import { useCoopConfig } from '../../../components/content/coop-config.jsx';
import { Colors } from '../../../constants';
import SearchColumn from '../../../components/search/search-column.jsx';
import colourStyles from '../../../utils/colourStyles';
import Select from 'react-select';
import transformWord from '../../../utils/transformWord.js';
import getBackgroundColor from '../../../utils/getBackgroundColor.js';
import getColor from '../../../utils/getColor.js';

const EntregasXLoteList = () => {
  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [registros, setRegistros] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  //const [ids, setIds] = useState([]);
  const [folios, setFolios] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [date, setDate] = useState(null);
  const [report, setReport] = useState('all');
  const [blockingReport, setBlockingReport] = useState(false);
  const [btnLotes, setBtnLotes] = useState(true);
  const [lotes, setLotes] = useState([]);
  const [idLote, setIdLote] = useState(0);
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);
  const dialogAsignarLote = createRef();
  const dialogNuevoLote = createRef();
  const dialogInfoLote = createRef();
  const dialogCambiarEstado = createRef();
  const [dataLotes, setDataLotes] = useState([]);
  const [infoLote, setInfoLote] = useState(false);
  // const [idMunicipio, setIdMunicipio] = useState(0);
  const [estados, setEstados] = useState([]);
  const [producto, setProducto] = useState('café');

  useEffect(() => {
    if (coopConfig?.config?.cooperativa?.producto?.nombre) {
      setProducto(coopConfig.config.cooperativa.producto.nombre);
    }
  }, [coopConfig.config]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ENTREGA',
        columns: [
          {
            Header: (
              <input
                type="checkbox"
                name="entregasAll"
                onChange={e => handleCheckAll(e)}
                style={{ width: 20, height: 20, accentColor: Colors.primary_600, display: data.length > 0 ? 'block' : 'none' }}
                checked={idsSeleccionados.length > 0 && idsSeleccionados.length === originalData.length}
                ref={el => el && (el.indeterminate = idsSeleccionados.length > 0 && originalData.length > idsSeleccionados.length)}
              />
            ),
            id: 'acopio_uuid',
            //accessor: 'acopio_uuid',
            sortable: false,
            width: 50,
            Cell: ({ row }) => {
              return (
                <>
                  <div className="form-check">
                    <CheckboxList
                      name="entregas"
                      value={row.original.acopio_uuid}
                      handleCheck={handleCheck}
                      checked={idsSeleccionados.includes(row.original.acopio_uuid) === true}
                    />
                  </div>
                </>
              );
            },
          },
          {
            Header: 'FECHA ENTREGA',
            accessor: 'fecha_acopio',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => {
              return <p className="text-left">{value != null ? value.substr(0, 10) : ''}</p>;
            },
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus_entrega',
            sortable: true,
            minWidth: 80,
            show: !btnLotes,
            Cell: ({ value, row }) => {
              return <LabelEstatus color={row.original.estatus_color_entrega} label={row.original.estatus_entrega} />;
            },
          },
          {
            Header: 'FOLIO ENTREGA',
            accessor: 'folio_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'FOLIO/LOTE',
            accessor: 'nombre_lote',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.nombre_lote != null) {
                return value;
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            Cell: ({ value, row }) => {
              let v = value;
              return (
                <LinkDownload
                  url={`recibos/entregas/descargar/${row.original.acopio_uuid}`}
                  label="Descargar"
                  icon={env.images.icon.pdf}></LinkDownload>
              );
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'CLAVE',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE ENTREGA',
        columns: [
          {
            Header: 'Nº RECIPIENTES',
            accessor: 'no_bultos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: `PESO BRUTO (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'peso_bruto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PESO TARA (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'peso_tara',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'peso_neto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'RENDIMIENTO',
            accessor: 'rendimiento',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_rendimiento,
          },
          {
            Header: 'HUMEDAD',
            accessor: 'humedad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_mancha,
          },
          {
            Header: 'DEFECTO',
            accessor: 'mancha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_humedad,
          },
          {
            Header: 'COLOR',
            accessor: 'color',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_color,
          },
          {
            Header: 'OBSERVACIONES',
            accessor: 'observaciones',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_observaciones,
          },
          {
            Header: 'TIPO DE ' + transformWord(producto, ['uppercase']) + ' ENTREGADO',
            accessor: 'tipos_cafe',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarTipo(),
          },
          {
            Header: 'ESTATUS COSTALERA',
            accessor: 'nombre_cat_costaleras_estatus',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarCostalera(),
            Cell: ({ value }) => {
              if (value != null) {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {value}
                  </p>
                );
              } else {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {'-'}
                  </p>
                );
              }
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PAGO',
        columns: [
          {
            Header: `PRECIO A PRODUCTOR (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`,
            accessor: 'precio_por_kg',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})`,
            accessor: 'importe_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'DESCUENTOS DEUDAS',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})`,
            accessor: 'pagado_a_productor',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})`,
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'MÉTODO DE PAGO',
            accessor: 'metodo_pago',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
    ],
    [idsSeleccionados, btnLotes, data]
  );

  const columnsLotes = React.useMemo(
    () => [
      {
        Header: 'NOMBRE',
        accessor: 'nombre',
        sortable: true,
        minWidth: 80,
        Cell: ({ value, row }) => {
          return (
            <>
              <a
                onClick={() => setInfoLote(row.original)}
                className="btn btn-link btn-lg text-left d-inline p-0"
                style={{ color: Colors.primary_500 }}>
                <i className="fa fa-edit"></i>
              </a>
              <a
                onClick={() => setIdLote(row.original.id)}
                className="btn btn-link text-left d-inline"
                style={{ color: Colors.primary_500 }}>
                <span>{value}</span>
              </a>
            </>
          );
        },
      },
      {
        Header: 'CÓDIGO',
        accessor: 'folio',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.folio != null) {
            return value;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'VOLUMEN A ENTREGAR',
        accessor: 'volumen',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.volumen != null) {
            return <LabelNumber value={value} currency={false} digits={2} position="text-left"></LabelNumber>;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'VOLUMEN ACOPIADO',
        accessor: 'lote_kgs_entregados',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.lote_kgs_entregados != null) {
            return <LabelNumber value={value} currency={false} digits={2} position="text-left"></LabelNumber>;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'AVANCE ACOPIO',
        accessor: 'avance_acopio',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          let avance_por = ((Number(row.original.lote_kgs_entregados) / Number(row.original.volumen)) * 100).toFixed(2) + ' %';
          if (row.original.volumen != null) {
            return <LabelNumber value={avance_por} currency={false} suffix=" %" digits={2} position="text-left"></LabelNumber>;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'ESTADO',
        sortable: true,
        minWidth: 200,
        Cell: ({ value, row }) => {
          return (
            <Select
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              key={row.original.id}
              loading={blocking}
              options={estados}
              onChange={value => handlerSelectChange(value, row.original.uuid)}
              value={estados.find(it => it.value == row.original.id_recoleccion_acopio_estatus)}
              styles={colourStyles}
            />
          );
        },
      },
      {
        Header: 'FECHA DE ENTREGA O VENTA',
        accessor: 'fecha_entrega_venta',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.fecha_entrega_venta != null) {
            return value;
          } else {
            return '-';
          }
        },
      },
    ],
    [idsSeleccionados, estados]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'fecha_acopio', label: 'FECHA ENTREGA' },
      { value: 2, target: 'estatus', label: 'ESTATUS' },
      { value: 3, target: 'folio_entrega', label: 'FOLIO ENTREGA' },
      { value: 4, target: 'fecha_entrega', label: 'FECHA ENTREGA' },

      { value: 5, target: 'productor_clave_tx', label: 'CLAVE' },
      { value: 6, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 7, target: 'folio_acopio', label: 'FOLIO RECIBIDO' },
      { value: 8, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 9, target: 'localidad', label: 'LOCALIDAD' },
      { value: 10, target: 'region', label: 'REGIÓN' },
      { value: 11, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 12, target: 'cuenta_categoria', label: 'CATEGORÍA' },

      { value: 13, target: 'no_bultos', label: 'Nº RECIPIENTES' },
      { value: 14, target: 'peso_bruto', label: `PESO BRUTO (${coopConfig.acopioUnits()?.abreviacion_plural})` },
      { value: 15, target: 'peso_tara', label: `PESO TARA (${coopConfig.acopioUnits()?.abreviacion_plural})` },
      { value: 16, target: 'peso_neto', label: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion_plural})` },
      { value: 17, target: 'precio_por_kg', label: 'PRECIO A PRODUCTOR (PERG)' },
      { value: 18, target: 'importe_total', label: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})` },
      { value: 19, target: 'total_anticipos_recuperados', label: 'DESCUENTOS DEUDAS' },
      { value: 20, target: 'pagado_a_productor', label: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})` },
      { value: 21, target: 'total_saldo', label: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})` },
    ],
    []
  );

  useEffect(() => {
    if (ciclo.id) {
      dialogNuevoLote.current.setIdCiclo(ciclo.id);
      dialogAsignarLote.current.setIdCiclo(ciclo.id);
      dialogCambiarEstado.current.setIdCiclo(ciclo.id);
      getLotes();
    }
  }, [ciclo]);

  useEffect(() => {
    if (ciclo.id > 0) {
      //updateData(ciclo.id);
      filtrarPorLote();
      if (btnLotes) {
        dialogInfoLote.current.setIdLote(idLote);
        idLote > 0 && dialogInfoLote.current.show();
      }
    }
  }, [idLote]);

  useEffect(() => {
    if (typeof infoLote.id !== 'undefined') {
      dialogNuevoLote.current.setData(infoLote);
    }
  }, [infoLote]);

  useEffect(() => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    setDate(curr.toISOString().substring(0, 10));

    subscribe('Lote::onSave', e => handleSave(e));
    subscribe('LoteAsignado::onSave', e => handleSaveAsignado(e));
    subscribe('CambioEstado::onSave', e => handleCambioEstado(e));
    getEstados();
    return () => {
      unsubscribe('Lote::onSave');
      unsubscribe('LoteAsignado::onSave');
      unsubscribe('CambioEstado::onSave');
    };
  }, []);

  const setFilter = value => {
    setCiclo(value.target);
    updateData(value.target.id);
  };

  const filtrarPorLote = () => {
    if (idLote > 0) {
      setData(originalData.filter(d => Number(d.id_lote) === Number(idLote)));
    } else {
      setData(originalData);
    }
  };

  const updateData = id_ciclo => {
    setBlocking(true);
    let filter = [{ column: 'id_ciclo', operator: '=', value: id_ciclo }];
    if (idLote > 0) {
      filter.push({ column: 'id_lote', operator: '=', value: idLote });
    }
    Api.Acopio.search('entregas_por_productor', { filter: filter })
      .then(async response => {
        let _data = response.data;
        setOriginalData(_data);
        var folios = _data
          .filter(function(item) {
            return item.folio_entrega != null && item.folio_entrega != '-' && item.folio_entrega != '';
          })
          .map(function(item) {
            return { value: item.folio_entrega, label: item.folio_entrega, date: item.fecha_entrega + ' 12:00:00' };
          })
          .sort(function(a, b) {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            return 0;
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                t => t.value === value.value // && t.name === value.name
              )
          )
          .sort(function(a, b) {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });

        setFolios(folios);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const handleSave = event => {
    //setIds([]);

    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se registró con éxito el lote ${event.detail.nombre}.`,
    });
    getLotes();
  };

  const handleSaveAsignado = event => {
    setIdsSeleccionados([]);
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Entregas asociadas al lote seleccionado.`,
    });
    setIdLote(0);
    getLotes();
    updateData(event.detail.id_ciclo);
  };

  const handleCambioEstado = event => {
    setIdsSeleccionados([]);
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Cambio de estado realizado.`,
    });
    setIdLote(0);
    updateData(event.detail.id_ciclo);
  };

  const handleDownloadReport = event => {
    event.preventDefault();
    let reportUrl = `xls/entregas_acopios/${ciclo?.uuid}`;
    if (report !== 'all') {
      // reportUrl = `xls/entregas_acopios/${ciclo?.uuid}/2022-12-14`;
      reportUrl = `xls/entregas_acopios/${ciclo?.uuid}/${date}`;
    }

    setBlockingReport(true);
    Api.download(reportUrl)
      .then(response => {
        let fileName = response.headers['content-disposition'].split('filename=')[1];

        const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //this is the name with which the file will be downloaded
        link.click();
        // no need to append link as child to body.
        setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
        setBlockingReport(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        setBlockingReport(false);
      });
  };

  const getLotesInfo = () => {
    setBtnLotes(true);
  };

  const getEstadoInfo = () => {
    setBtnLotes(false);
  };

  const getLotes = () => {
    Api.get('admin/acopios/entregas/lotes', localStorage.getItem(`${env.appName}-ciclo_id`), {})
      .then(response => {
        let lotes = response.data;
        setDataLotes(lotes);
        setLotes(
          lotes.map(function(lote) {
            return { label: lote.nombre, value: lote.id };
          })
        );
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
      });
  };

  const handleCheckAll = event => {
    if (event.target.checked === true) {
      setCheckData(data);
    } else {
      setIdsSeleccionados([]);
    }
  };

  const handleCheck = event => {
    let idsSel = idsSeleccionados;
    if (event.target.checked === true) {
      setIdsSeleccionados([...idsSel, event.target.value]);
    } else {
      idsSel = idsSel.filter(item => item !== event.target.value);
      setIdsSeleccionados(idsSel);
    }
  };

  const openModalAsignarLote = () => {
    if (idsSeleccionados.length > 0) {
      dialogAsignarLote.current.show();
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: `Debe seleccionar al menos una entrega pora asignar`,
      });
    }
  };

  const openModalCambiarEstado = () => {
    if (idsSeleccionados.length > 0) {
      dialogCambiarEstado.current.show();
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: `Debe seleccionar al menos una entrega cambiar el estado`,
      });
    }
  };

  const getNombreLote = id_lote => {
    let nombre = '';
    if (id_lote > 0) {
      let _lote = dataLotes.filter(lote => lote.id === id_lote);
      nombre = _lote[0]?.nombre;
    }
    return nombre;
  };

  const exportarXlsx = () => {
    if (idLote === 0 && btnLotes) {
      LotesExport(dataLotes, ciclo, estados);
    } else {
      LotesEntregasExport(data, getNombreLote(idLote), coopConfig.mostrarCostalera());
    }
  };

  // const handleShow = () => {
  //   dialogInfoLote.current.show();
  // };

  const setCheckData = () => {
    if (data.length > 0) {
      // console.log('setCheckData');
      // console.log('event.target.checked === true');
      let uuids = [];
      let idSel = idsSeleccionados;
      if (idSel.length === 0) {
        console.log('idSel.length === 0');
        uuids = data.map(d => {
          return d.acopio_uuid;
        });
      } else {
        // console.log('idSel.length > 0');
        // console.log('data.length:' + data.length);
        // console.log('idSel.length:' + idSel.length);
        let uuidsData = data.map(d => {
          return d.acopio_uuid;
        });
        uuids = idSel.concat(uuidsData.filter(item => idSel.indexOf(item) < 0));
      }
      setIdsSeleccionados(uuids);
    }
  };

  useEffect(() => {
    setRegistros(originalData);
    setData(originalData);
  }, [originalData]);

  const handlerSelectChange = (selectedOption, uuid) => {
    setBlocking(true);
    Api.update('admin/acopios/lotes/cambia_estado_lote', uuid, { id_recoleccion_acopio_estatus: selectedOption.value })
      .then(response => {
        getLotes();
        updateData(ciclo.id);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
      });
  };

  const getEstados = () => {
    Api.getAll('acopios/catalogos/recoleccion_acopios_estatus')
      .then(response => {
        let estados = response.data;
        setEstados(
          estados.map(function(estado) {
            return { label: estado.nombre, value: estado.id, color: estado.color };
          })
        );
      })
      .catch(e => {
        // eslint-disable-next-line no-console
      });
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Entregas</li>
        </ol>

        <div className="btn-group" role="group" style={{ borderRadius: 1 }}>
          <button
            type="button"
            onClick={() => getLotesInfo()}
            className={'btn btn-light'}
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: btnLotes ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
            }}
            disabled={false}>
            Lotes
          </button>

          <button
            type="button"
            onClick={() => getEstadoInfo()}
            className={'btn btn-light'}
            style={{
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              backgroundColor: !btnLotes ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
            }}
            disabled={false}>
            Estado
          </button>
        </div>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-2">
            <SelectCiclo
              width="150px"
              setFilter={evt => {
                setFilter(evt);
              }}
              acopio
            />
          </div>

          <div className="col-4">
            <FormSelectMaterial
              id="lote"
              error={false}
              label={'Lote'}
              value={idLote}
              onChange={setIdLote}
              options={lotes}
              showAll={true}
              showAllLabel="Elige un lote"
            />
          </div>
          <div className="col-4">
            {idLote === 0 && btnLotes ? (
              <button
                style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
                className="btn btn-outline-secondary btn-sirio"
                onClick={() => {
                  setInfoLote(false);
                  dialogNuevoLote.current.show();
                }}>
                <i className="fa fa-plus"></i> Registrar nuevo lote
              </button>
            ) : (
              idLote > 0 && (
                <div onClick={() => setIdLote(0)} className="div-lote-seleccionado">
                  <span className="texto-lote-seleccionado">{'Lote: ' + getNombreLote(idLote)}</span>
                  <span className="x-lote-seleccionado">x</span>
                </div>
              )
            )}
          </div>

          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <Link to="#" onClick={() => exportarXlsx()} className="m-r-2" style={{ color: '#789D16' }}>
                  <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
                </Link>
                {menuVisible === true && (
                  <form className="dropdown-menu dropdown-menu dropdown-menu-right" style={{ display: 'block', padding: '16px', top: 32 }}>
                    <div className="form-check pb-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="report"
                        value="all"
                        checked={report === 'all'}
                        onClick={() => setReport('all')}
                      />
                      <label className="form-check-label" htmlFor="dropdownCheck">
                        Todas las entregas
                      </label>
                    </div>
                    <div className="form-check pb-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="report"
                        value="date"
                        checked={report !== 'all'}
                        onClick={() => setReport('date')}
                      />
                      <label className="form-check-label" htmlFor="dropdownCheck">
                        Entregas por fecha
                      </label>
                    </div>
                    {report === 'date' && (
                      <div className="form-group">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="DD/MM/YYYY"
                          defaultValue={date}
                          onChange={e => setDate(e.target.value)}
                        />
                      </div>
                    )}
                    {blockingReport && (
                      <div className="d-flex justify-content-start float-right">
                        <div
                          className="spinner-border text-warning mr-2"
                          role="status"
                          aria-hidden="true"
                          style={{ height: 24, width: 24 }}></div>
                        <span className="mt-1">Descargando ...</span>
                      </div>
                    )}
                    {!blockingReport && (
                      <button
                        type="submit"
                        className="btn btn-xs btn-outline-secondary btn-sirio float-right"
                        onClick={e => handleDownloadReport(e)}>
                        Descargar
                      </button>
                    )}
                  </form>
                )}
              </div>
            )}
          </div>
        </div>

        {idLote === 0 && btnLotes && <NoPagination blocking={blocking} selected="id" columns={columnsLotes} data={dataLotes} />}

        {!btnLotes && (
          <div style={{ marginTop: 10 }}>
            <NoPagination
              blocking={blocking}
              selected="id"
              columns={columns}
              data={data}
              fnBackgroundColor={getBackgroundColor}
              fnColor={getColor}></NoPagination>

            <div className="row mt-3">
              <div className="col-md-12">
                <p>{idsSeleccionados.length + ' seleccionados de un total de ' + originalData.length + ' registros'}</p>
              </div>

              <div className="col-md-12">
                <button
                  style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
                  className="btn btn-sirio"
                  onClick={() => openModalCambiarEstado()}>
                  <i className="fa fa-plus"></i> Cambiar estado
                </button>
              </div>
            </div>
          </div>
        )}
      </CardContainer>

      {!btnLotes && (
        <div className="mt-3">
          <CardContainer height={'100%'}>
            <LotesGraficaEstatus data={data} />
          </CardContainer>
        </div>
      )}

      {btnLotes && (
        <CardContainer height={'100%'} style={{ marginTop: 10 }}>
          <div className="row">
            <div className="col-lg-6">
              {idLote === 0 && (
                <p>
                  <span className="entregas-titulo">ENTREGAS</span>
                  <span className="entregas-subtitulo"> Asigna o edita entregas a los lotes</span>
                </p>
              )}
            </div>
            <div className="col-lg-6">
              <SearchColumn
                data={originalData}
                columns={searchColumns}
                setDataFilter={evt => {
                  setDataFilter(evt);
                }}></SearchColumn>
            </div>
          </div>

          {/* idLote !== 0 && (
        <div className='div-nombre-lote'> {getNombreLote(idLote)}
        <div style={{height : 20, width : 20, float : 'right'}}>
          <span onClick={() => handleShow()}>{'<<'}</span>
        </div>
        </div>
       )*/}

          <NoPagination
            blocking={blocking}
            selected="id"
            columns={columns}
            data={data}
            fnBackgroundColor={getBackgroundColor}
            fnColor={getColor}></NoPagination>

          <div className="row mt-3">
            <div className="col-md-12">
              <p>{idsSeleccionados.length + ' seleccionados de un total de ' + originalData.length + ' registros'}</p>
            </div>

            <div className="col-md-12">
              <button
                style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
                className="btn btn-sirio"
                onClick={() => openModalAsignarLote()}>
                <i className="fa fa-plus"></i> Asignar lote
              </button>
            </div>
          </div>
        </CardContainer>
      )}

      <ModalAsignarLote
        lotes={lotes}
        entregas={idsSeleccionados}
        ref={dialogAsignarLote}
        title="Asignar entregas a lote"></ModalAsignarLote>
      <ModalCambiarEstado entregas={idsSeleccionados} ref={dialogCambiarEstado} title="Cambiar estado de entregas"></ModalCambiarEstado>
      <ModalNuevoLote
        ref={dialogNuevoLote}
        title={(typeof infoLote.id !== 'undefined' && idLote === 0 ? 'Actualiza' : 'Nuevo') + ' lote'}></ModalNuevoLote>
      <ModalInfoLote ref={dialogInfoLote} title=""></ModalInfoLote>
    </div>
  );
};

export default EntregasXLoteList;
