import React from 'react';
import { useAuth } from './auth';
import { Redirect } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import { useCoopConfig } from './coop-config';
import Loading from '../construction/Loading';

export default function AuthRoute({ path, exact, component }) {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { loadingConfig } = useCoopConfig();

  return (
    <Route
      render={() =>
        user || pathname === '/user/login-v3' ? (
          <Route path={path} exact={exact} component={loadingConfig && pathname !== '/user/login-v3' ? Loading : component} />
        ) : (
          <Redirect to="/user/login-v3" />
        )
      }
    />
  );
}
