import { useEffect, useState } from 'react';
import eliminarPuntosDuplicados from '../utils/eliminarPuntosDuplicados';
import getAlphaOrder from '../utils/getAlphaOrder';

const colors = [
  '#FF6B6B',
  '#778BEB',
  '#FFE66D',
  '#E84393',
  '#45B7D1',
  '#D6A2E8',
  '#FF9F43',
  '#96E6A1',
  '#2C3E50',
  '#F7D794',
  '#4ECDC4',
  '#F8C291',
  '#A8E6CF',
  '#6C5CE7',
  '#EC5858',
  '#9B59B6',
  '#72B01D',
  '#FF9FF3',
  '#786FA6',
  '#FEA47F',
];

let historyArr = [];
let historyIndex = -1;

const useCroquisEditHook = ({ parcelaPolygon, centroid }, intersecciones) => {
  const zoom = 19;
  const [center, setCenter] = useState({ lat: 16, lng: -92 });
  const [areaGMaps, setAreaGMaps] = useState(null);
  const [areaGMapsOG, setAreaGMapsOG] = useState(null);
  const [pointsOnMap, setPointsOnMap] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [elPoly, setElPoly] = useState(null);
  const [intersectPolygons, setIntersectPolygons] = useState([]);

  function saveState(paths) {
    const pathsCopy = paths.map(point => ({
      lat: point.lat(),
      lng: point.lng(),
    }));
    calculateLengths(pathsCopy);
    getPointsOnMap(pathsCopy);
    const area = pathsCopy ? maps.geometry.spherical.computeArea(pathsCopy) / 10000 : 0;
    if (area) {
      setAreaGMaps(area?.toFixed(2));
    }

    // Remove any states after current index if we're in middle of history
    const index = historyIndex + 1;
    const polygonHistory = historyArr.slice(0, index);

    polygonHistory.push(pathsCopy);
    historyIndex = index;
    historyArr = polygonHistory;
  }

  const calculateLengths = poligono_data_filtrado => {
    // Distancia entre cada punto del polígono
    // ubicado entre los dos puntos
    const lengthsTemp = [];
    const total = poligono_data_filtrado.length;
    if (total > 2) {
      let curr, prev, dist;
      for (let i = 1; i < total; i++) {
        curr = poligono_data_filtrado[i];
        prev = poligono_data_filtrado[i - 1];
        dist = maps.geometry.spherical.computeDistanceBetween(prev, curr);
        lengthsTemp.push({
          length: dist.toFixed(2),
          title: `${getAlphaOrder(i - 1, total)} - ${getAlphaOrder(i, total)}`,
        });
      }
      const lastIndex = total - 1;
      curr = poligono_data_filtrado[lastIndex];
      prev = poligono_data_filtrado[0];
      dist = maps.geometry.spherical.computeDistanceBetween(curr, prev);
      lengthsTemp.push({
        length: dist.toFixed(2),
        title: `${getAlphaOrder(lastIndex, total)} - ${getAlphaOrder(0, total)}`,
      });
    }
    setLengths(lengthsTemp);
  };

  function getPointsOnMap(poligono_data_filtrado) {
    const points = [];
    poligono_data_filtrado.forEach((pnt, i) => {
      points.push({
        lat: pnt.lat,
        lng: pnt.lng,
        title: getAlphaOrder(i, poligono_data_filtrado.length),
      });
    });

    setPointsOnMap(points);
  }

  useEffect(() => {
    if (map != null && maps != null && maps.geometry) {
      if (parcelaPolygon != null) {
        const poligono = JSON.parse(parcelaPolygon);
        let poligono_data = poligono.coordinates[0].map(function(p) {
          return { lat: p[1], lng: p[0] };
        });
        poligono_data = eliminarPuntosDuplicados(poligono_data);

        const polygon = new maps.Polygon({
          paths: poligono_data,
          strokeColor: '#91B833',
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: '#f2f1e8',
          fillOpacity: 0.5,
          editable: true,
          draggable: true,
        });
        setElPoly(polygon);
        polygon.setMap(map);
        if (centroid && centroid.lat && centroid.lng) {
          setCenter({ lat: centroid.lat, lng: centroid.lng });
        } else {
          const latCenter = parseFloat(poligono.coordinates[0][0][1]);
          const lngCenter = parseFloat(poligono.coordinates[0][0][0]);
          setCenter({ lat: latCenter, lng: lngCenter });
        }

        polygon.addListener('dragend', () => {
          // console.log('EVENTO', 'dragend');
          polygon.dragging = false;
          saveState(polygon.getPath().getArray());
        });

        polygon.addListener('dragstart', () => {
          // console.log('EVENTO', 'dragstart');
          polygon.dragging = true;
        });

        polygon.getPaths().forEach(function(path) {
          maps.event.addListener(path, 'insert_at', function() {
            if (!polygon.dragging) {
              // console.log(' New point', path);
              saveState(polygon.getPath().getArray());
            }
          });

          maps.event.addListener(path, 'remove_at', function() {
            if (!polygon.dragging) {
              // console.log(' Point was removed', path);
              saveState(polygon.getPath().getArray());
            }
          });

          maps.event.addListener(path, 'set_at', function() {
            if (!polygon.dragging) {
              // console.log(' Point was moved', path);
              saveState(polygon.getPath().getArray());
            }
          });
        });
        maps.event.addListener(polygon, 'rightclick', function(event) {
          if (event.vertex == undefined) {
            return;
          } else {
            var path = polygon.getPath();
            path.removeAt(event.vertex);
          }
        });

        const area = poligono_data ? maps.geometry.spherical.computeArea(poligono_data) / 10000 : 0;
        setAreaGMapsOG(area?.toFixed(2));

        saveState(polygon.getPath().getArray());
      }
    }
  }, [map, maps, parcelaPolygon]);

  useEffect(() => {
    if (map != null && maps != null && maps.geometry && intersecciones.length > 0) {
      const inters = [];
      for (let i = 0; i < intersecciones.length; i++) {
        let poligono_data = intersecciones[i]?.poligono?.coordinates[0].map(function(p) {
          return { lat: p[1], lng: p[0] };
        });
        poligono_data = eliminarPuntosDuplicados(poligono_data);

        const polygon = new maps.Polygon({
          paths: poligono_data,
          strokeColor: colors[i % colors.length],
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: colors[i % colors.length],
          fillOpacity: 0.4,
          visible: true,
          id: intersecciones[i]?.id,
          nombre: intersecciones[i]?.nombre,
          color: colors[i % colors.length],
          cuenta: intersecciones[i]?.cuenta?.nombres + ' ' + intersecciones[i]?.cuenta?.apellido_paterno,
          uuid: intersecciones[i]?.uuid,
        });
        polygon.setMap(map);
        inters.push({ polygon, visible: true });
      }
      setIntersectPolygons(inters);
    }
  }, [map, maps, intersecciones]);

  const renderMap = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  function undoChange() {
    if (historyIndex > 0) {
      const index = historyIndex - 1;
      elPoly.setPath(historyArr[index]);
      calculateLengths(historyArr[index]);
      getPointsOnMap(historyArr[index]);
      historyIndex = index;
    }
  }

  function redoChange() {
    if (historyIndex < historyArr.length - 1) {
      const index = historyIndex + 1;
      elPoly.setPath(historyArr[index]);
      calculateLengths(historyArr[index]);
      getPointsOnMap(historyArr[index]);
      historyIndex = index;
    }
  }

  function hideShowIntersections(id) {
    const newIntersections = intersectPolygons.map(({ polygon, visible }) => {
      if (polygon.id === id) {
        polygon.setOptions({ visible: !polygon.visible });
        visible = !visible;
      }
      return { polygon, visible };
    });
    setIntersectPolygons(newIntersections);
  }

  const getPath = () => elPoly?.getPath()?.getArray();

  return {
    areaGMaps,
    areaGMapsOG,
    renderMap,
    center,
    pointsOnMap,
    lengths,
    zoom,
    undoChange,
    redoChange,
    getPath,
    // historyIndex,
    // historyArr,
    hideShowIntersections,
    intersectPolygons,
  };
};

export default useCroquisEditHook;
